import React, { useEffect, useState } from "react";
import "./MobileAdminPreOrder.css";
import { IMAGE_BASE } from "../../config";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateGST,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
  handleCurrentPrice,
} from "../../utils/utils";
import {
  fetchAllAdminUsersCartsAsync,
  selectAllAdminUserCart,
  selectAllAdminUserCarts,
  fetchAllUserCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";
import NoCartItem from "../../components/Carts/NoCartItem";
import { handleChangeQtyOfProductCarts, handleRemoveFromCarts } from "../../utils/dispatch";
import CloseIcon from "@mui/icons-material/Close";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { adminChangeQtyOfProductCarts, adminRemoveFromCarts } from "../../utils/admin";

function MobileAdminCarts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUserCart);
  const userCarts = useSelector(selectAllAdminUserCarts);
  const [totals, setTotals] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [openAccordian, setOpenAccordian] = useState(null);
  const handleAccordian = (index) => {
    if (openAccordian !== index) {
      setOpenAccordian(index);
    } else {
      setOpenAccordian(null);
    }
  };


  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersCartsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (users) {
      setFilteredUsers(
        users?.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery) ||
            user?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "cart_count") {
        sortedUsers.sort((a, b) => b?.CartCount - a?.CartCount);
      } else if (sortCriteria === "state_name") {
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllUserCartsAsync(UserID)).then(() => {
      handleClose();
    });
  };
  useEffect(() => {
    const getTotals = () => {
      let total = 0;
      let id = 0;

      for (let data of userCarts) {
        id = data?.UserID;
        const price = data?.skuDetails?.Offer_price
          ? calculateOfferPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.Offer_price),
              data?.skuDetails?.GST
            )
          : calculateDealerPrice(
              parseInt(data?.skuDetails?.MRP_price),
              parseInt(data?.skuDetails?.NDP_Price),
              data?.skuDetails?.GST
            );

        total += price * data?.buy_quantity;
      }

      setTotals((prevTotals) => ({
        ...prevTotals,
        [id]: formatWithCommasWithDecimal(total),
      }));
    };

    getTotals();
  }, [userCarts]);

  const calculateTotalCarts = () => {
    let totalCarts = 0;
    for (const cart of users) {
      totalCarts += cart?.CartCount;
    }
    return totalCarts;
  };
  return (
    <div className="mobile-admin-preorder-v2-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-admin-dashboard-middle-container">
        <div className="mobile-admin-dashboard-middle-search-container">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by name, phone or state"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mobile-admin-dashboard-search-input"
          />
        </div>
        <div className="mobile-admin-dashboard-sort-by-container">
          <div className="mobile-admin-dashboard-total-preorder-container">
            <span className="mobile-admin-dashboard-heading-total">
              Total Carts
            </span>
            <span className="mobile-admin-dashboard-total-count">
              {users && Array.isArray(users) ? calculateTotalCarts() : 0}
            </span>
            <ShoppingCartOutlinedIcon />
          </div>
          <div className="admin-dashboard-sort-by">
            <span className="admin-dashboard-sort-by-name">Sort by:</span>
            <select
              name="sort-by"
              id="admin-dashboard-sort-by-id"
              className="bg-white-1"
              value={sortCriteria}
              onChange={(e) => setSortCriteria(e.target.value)}
            >
              <option value="">None</option>
              <option value="last_login">Last Login</option>
              <option value="cart_count">Cart Count</option>
              <option value="state_name">State</option>
            </select>
          </div>
        </div>
      </div>
      {filteredUsers && Array?.isArray(filteredUsers) ? (
        <div className="mobile-admin-dashboard-main-user-preorder-container">
          <div className="mobile-admin-dashboard-main-user-preorder-header">
            <span className="mobile-admin-dashboard-main-user-preorder-heading">
              Recent Carts
            </span>
          </div>
          <div className="mobile-admin-dashboard-main-user-item-head">
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"8%",textAlign:'center'}}>
              Login Date
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"18%",textAlign:'center'}}>
              User Name
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"24%",textAlign:'center'}}>
              Phone Number
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"15%",textAlign:'center'}}>
              State Name
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"10%",textAlign:'center'}}>
              Cart Cnt.
            </span>
            <span className="mobile-admin-dashboard-item-each-head" style={{fontSize:"12px",width:"25%",textAlign:'center'}}>Total Order Amount</span>
          </div>
          <div className="mobile-admin-dashboard-main-user-prebook-container">
            {filteredUsers &&
              Array?.isArray(filteredUsers) &&
              filteredUsers?.map((user, index) => (
                <div
                  className="mobile-admin-dashboard-main-each-user"
                  key={index}
                >
                  <div
                    className="mobile-admin-dashboard-main-each-user-top"
                    style={{
                      boxShadow:
                        user?.UserID === openOrder &&
                        "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                    }}
                    key={index}
                  >
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"8%",textAlign:'center'}}>
                    {user?.LastLogin !== null ? (
                          new Date(user?.LastLogin).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                            }
                          )
                        ) : (
                          <b
                            style={{
                              color: "var(--color-peach)",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            Not Login
                          </b>
                        )}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"18%",textAlign:'center'}}>
                      {user?.FirstName}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"24%",textAlign:'center'}}>
                      {user?.PhoneNumber}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"15%",textAlign:'center'}}>
                      {user?.stateName}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"10%",textAlign:'center'}}>
                      {user?.CartCount}
                    </span>
                    <span className="mobile-admin-user-each-attr" style={{fontSize:"12px",width:"25%",textAlign:'center'}}>
                      {totals && totals[user?.UserID]
                        ? totals[user?.UserID]
                        : "--------"}
                     {openOrder === user?.UserID ? (
                        <KeyboardArrowDownOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(null)}
                        />
                      ) : (
                        <ChevronRightOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenOrder(user?.UserID);
                            findAllOrderUsingUserID(user?.UserID);
                          }}
                        />
                        
                      )}
                    </span>
                  </div>
                  {openOrder === user?.UserID && (
                    <div className="mobile-admin-user-bottom-products">
                      <div className="main-carts-products-div-container">
                        {userCarts && userCarts?.length > 0 && (
                          <div className="mobile-main-carts-products-contain-div">
                            {userCarts?.map((data, index) => (
                              <div className="w-full flex flex-col items-start justify-start bg-white-1 mx-0 px-3 relative">
                              <CloseIcon
                                className="absolute top-2 left-2 z-10 text-black-1/80 p-1 rounded-full bg-white-1 bg-black-1/20 shadow-sm shadow-black-1/20"
                                style={{ fontSize: "20px" }}
                                onClick={() =>
                                  adminRemoveFromCarts(
                                    data,
                                    user?.UserID,
                                    dispatch,
                                    handleOpen,
                                    handleClose
                                  )
                                }
                              />
                              <div className="w-full h-[135px] flex items-start justify-start">
                                <div
                                  className="w-[38%] h-full flex-center relative"
                                  onClick={() =>
                                    navigate(
                                      `/product/${
                                        data?.skuDetails?.prod_ID && data?.skuDetails?.prod_ID
                                      }`
                                    )
                                  }
                                >
                                  <img loading="lazy"
                                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                      data?.images && data?.images
                                    }`}
                                    alt="product-img"
                                    className="object-contain"
                                  />
                                </div>
                                {data?.skuDetails?.SKU_ID?.startsWith("D") && (
                                  <span
                                    className="main-carts-product-not-available"
                                    style={{
                                      color: "var(--color-peach)",
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      position: "absolute",
                                      top: "5px",
                                      right: "10px",
                                    }}
                                  >
                                    Not Available
                                  </span>
                                )}
                                <div className="w-[62%] h-full flex flex-col items-start justify-between ml-4 relative pt-2">
                                  <div className="w-[50%] h-[22px]">
                                    {data && data?.skuDetails?.manufacture_images ? (
                                      <img loading="lazy"
                                        className="max-w-[120px] h-full object-contain"
                                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                          data && data?.skuDetails?.manufacture_images
                                        }`}
                                        alt="brand-logo"
                                      />
                                    ) : (
                                      <h3 className="text-16 italic font-bold">
                                        {data?.skuDetails?.manufacture_name &&
                                          data?.skuDetails?.manufacture_name}
                                      </h3>
                                    )}
                                  </div>
                                  <span className="w-full h-[55px] text-start font-sans leading-[1] mt-1 text-[18px] text-black-2 font-bold capitalize">
                                    {data?.skuDetails?.prod_name &&
                                      (data?.skuDetails?.prod_name)
                                        .slice(0, 45)
                                        .toLowerCase()
                                        .charAt(0)
                                        .toUpperCase() +
                                        (data?.skuDetails?.prod_name)
                                          .slice(0, 45)
                                          .toLowerCase()
                                          .slice(1)}
                                    {(data?.skuDetails?.prod_name).length > 45 && "..."}
                                  </span>
                                  <div className="flex items-center justify-between font-sans leading-[1] bg-gray-100 px-2 py-[2px] mt-1 rounded-sm font-bold shadow-sm shadow-black-1/20">
                                    <div className="text-[14px] font-medium text-black-2 flex items-center">
                                      <span>4.7</span>
                                      <StarRoundedIcon
                                        className="ml-1 text-[#daa520]"
                                        style={{ fontSize: "16px" }}
                                      />
                                    </div>
                                    <span className="text-[14px] text-black-1 mx-[2px] font-medium">
                                      |
                                    </span>
                                    <span className="text-[14px] ml-2 font-medium text-black-2">
                                      4K
                                    </span>
                                  </div>
                                  <div className="w-full flex flex-col">
                                    <span className="w-full h-[28px]  text-[16px] leading-[1] font-sans font-semibold text-blue flex items-center whitespace-nowrap">
                                      Dealer Price- ₹
                                      <b
                                        style={{
                                          textDecoration:
                                            data?.skuDetails?.Offer_price !== null && "line-through",
                                          fontSize: data?.skuDetails?.Offer_price !== null && "14px",
                                        }}
                                      >
                                        {formatWithCommasWithDecimal(
                                          calculateDealerPrice(
                                            parseInt(data?.skuDetails?.MRP_price),
                                            parseInt(data?.skuDetails?.NDP_Price),
                                            data?.skuDetails?.GST
                                          )
                                        )}
                                      </b>
                                      {Object.keys(userDetail).length > 0 &&
                                        data?.skuDetails?.Offer_price !== null && (
                                          <span className="text-[18px] text-green ml-1 font-semibold">
                                            ₹
                                            {formatWithCommasWithDecimal(
                                              calculateOfferPrice(
                                                parseInt(data?.skuDetails?.MRP_price),
                                                parseInt(data?.skuDetails?.Offer_price),
                                                data?.skuDetails?.GST
                                              )
                                            )}
                                          </span>
                                        )}
                                    </span>
                                    <span className="w-full flex items-start text-[15px] leading-[1] font-sans font-semibold text-blue">
                                      Product MRP- ₹
                                      {formatWithCommasWithDecimal(
                                        parseInt(data?.skuDetails?.MRP_price)
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full flex items-center justify-between mt-3 mb-1">
                                <div className="flex items-center">
                                  <RemoveSharpIcon
                                    onClick={() => {
                                      const bundleSize = parseInt(data?.skuDetails?.Bundle);
                                      const currentQuantity = parseInt(data?.buy_quantity);
                        
                                      if (currentQuantity === bundleSize) {
                                        return;
                                      } else {
                                        const newQuantity = Math.max(currentQuantity - bundleSize, 0);
                                        adminChangeQtyOfProductCarts(
                                          data,
                                          user?.UserID,
                                          newQuantity,
                                          dispatch,
                                          handleOpen,
                                          handleClose
                                        );
                                      }
                                    }}
                                    className={`rounded-full mr-2 p-[2px] ${
                                      data?.buy_quantity === 1
                                        ? "bg-black-1/30"
                                        : "bg-green text-white-1"
                                    }`}
                                  />
                        
                                  <span className="text-[16px] font-sans leading-[1] text-black-1/80 w-[35px] h-[20px] border-[1px] border-black-1/10 text-center mx-2">
                                    {data?.buy_quantity}
                                  </span>
                                  <AddSharpIcon
                                    onClick={() => {
                                      const bundleSize = parseInt(data?.skuDetails?.Bundle);
                                      const currentQuantity = parseInt(data?.buy_quantity);
                        
                                      const newQuantity = currentQuantity + bundleSize;
                        
                                      adminChangeQtyOfProductCarts(
                                        data,
                                        user?.UserID,
                                        newQuantity,
                                        dispatch,
                                        handleOpen,
                                        handleClose
                                      );
                                    }}
                                    className={`rounded-full ml-2 p-[2px] ${
                                      data?.buy_quantity === data?.skuDetails?.qty
                                        ? "bg-black-1/30"
                                        : "bg-green text-white-1"
                                    }`}
                                  />
                                </div>
                                <div className="flex items-center">
                                  <div className="flex flex-col mr-1">
                                    <span className="text-[18px] font-sans leading-[1] text-black-1/80">
                                      ₹{" "}
                                      {formatWithCommasWithDecimal(
                                        parseInt(
                                          data?.skuDetails?.Offer_price
                                            ? calculateOfferPrice(
                                                parseInt(data?.skuDetails?.MRP_price),
                                                parseInt(data?.skuDetails?.Offer_price),
                                                data?.skuDetails?.GST
                                              )
                                            : calculateDealerPrice(
                                                parseInt(data?.skuDetails?.MRP_price),
                                                parseInt(data?.skuDetails?.NDP_Price),
                                                data?.skuDetails?.GST
                                              )
                                        ) * data?.buy_quantity
                                      )}
                                    </span>
                                    <span className="text-[16px] font-medium text-black-1/80 font-sans leading-[1]">
                                      Price Details
                                    </span>
                                  </div>
                                  {openAccordian !== index && (
                                    <KeyboardArrowDownSharpIcon
                                      className="text-black-1/80"
                                      style={{ fontSize: "25px" }}
                                      onClick={() => handleAccordian(index)}
                                    />
                                  )}
                                  {openAccordian === index && (
                                    <KeyboardArrowUpSharpIcon
                                      className="accordian-carts-icon text-black-1/80"
                                      style={{ fontSize: "25px" }}
                                      onClick={() => handleAccordian(index)}
                                    />
                                  )}
                                </div>
                              </div>
                              {openAccordian === index && (
                                <div className="w-full bg-gray-200/70 rounded-md py-2 my-2">
                                  <div className="w-full flex flex-col items-center justify-between">
                                    <div className="w-full flex items-center justify-between relative p-2">
                                      <p className="text-[14px] text-black-1/70 font-semibold">
                                        Price Per Quantity
                                      </p>
                                      <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
                                      <span className="text-[15px] text-black-1/80 font-semibold">
                                        ₹
                                        {formatWithCommasWithDecimal(
                                          parseInt(
                                            data?.skuDetails?.Offer_price
                                              ? calculateOfferPrice(
                                                  parseInt(data?.skuDetails?.MRP_price),
                                                  parseInt(data?.skuDetails?.Offer_price),
                                                  data?.skuDetails?.GST
                                                )
                                              : calculateDealerPrice(
                                                  parseInt(data?.skuDetails?.MRP_price),
                                                  parseInt(data?.skuDetails?.NDP_Price),
                                                  data?.skuDetails?.GST
                                                )
                                          )
                                        )}
                                      </span>
                                    </div>
                                    <div className="w-full flex items-center justify-between relative p-2">
                                      <p className="text-[14px] text-black-1/70 font-semibold">GST</p>
                                      <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
                                      <span className="text-[15px] text-black-1/80 font-semibold">
                                        ₹
                                        {formatWithCommasWithDecimal(
                                          calculateGST(
                                            parseInt(
                                              data?.skuDetails?.NDP_Price && data?.skuDetails?.NDP_Price
                                            ),
                                            parseInt(data?.skuDetails?.GST && data?.skuDetails?.GST)
                                          )
                                        )}
                                      </span>
                                    </div>
                                    <div className="w-full flex items-center justify-between relative p-2">
                                      <p className="text-[14px] text-black-1/70 font-semibold">
                                        Shipping To
                                      </p>
                                      <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
                                      <span className="text-[15px] text-black-1/80 font-semibold">
                                        {userDetail?.location}-{userDetail?.pin_code}
                                      </span>
                                    </div>
                        
                                    <div className="w-full flex items-center justify-between relative p-2">
                                      <p className="text-[14px] text-black-1/70 font-semibold">
                                        Total Price
                                      </p>
                                      <hr className="absolute w-[5px] border-[1px] border-black-1/90 left-[50%] transform -translate-x-1/2" />
                                      <span className="text-[15px] text-black-1/80 font-semibold">
                                        ₹{formatWithCommasWithDecimal(handleCurrentPrice(data))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <NoCartItem text={"Pre-Book"} />
      )}
    </div>
  );
}

export default MobileAdminCarts;
