import React, { useEffect, useState } from "react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  fetchAllPreorderCartsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreorders,
} from "../../features/cartPage/cartPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { useNavigate } from "react-router";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import ProfileSideBar from "../../mComponent/ProfileSideBar";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PreorderMobileCard from "../../dComponent/Preorder/PreorderMobileCard";
import PreorderCard from "../../dComponent/Preorder/PreorderCard";

function MyPreOrder() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const preorderCart = useSelector(selectAllPreorders);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    if (userDetail && userDetail?.UserID)
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
  }, [dispatch, userDetail]);

  const handleRemoveAllFromPreorderCarts = () => {
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(userDetail.UserID)).then(() => {
      dispatch(fetchAllPreorderCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;

    if (preorderCart && preorderCart?.length > 0) {
      for (const preorder of preorderCart) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  return (
    <div className="w-full h-auto flex flex-col py-0 px-0 pl-0 sm:py-2 sm:px-4 sm:pl-1 flex-1 max-sm:px-0 relative">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <p className="text-18 text-black-1 font-bold tracking-wider pt-1 flex max-sm:hidden">
        My Pre-orders
      </p>
      <div className="w-screen fixed top-[58px] left-0 right-0 z-999 bg-white-1 shadow-md shadow-black-1/20 py-3 px-3 flex items-center justify-between sm:hidden">
        <div className="w-auto flex items-center">
          <ChevronLeftOutlinedIcon
            onClick={() => navigate("/")}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
          <span className="text-16 text-black-1/80 font-bold flex items-center">
            My Pre-orders
          </span>
        </div>
        <div className="h-full">
          <MoreHorizOutlinedIcon
            onClick={handleSidebar}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
        </div>
        {openSidebar && (
          <ProfileSideBar
            handleSidebar={handleSidebar}
            openSidebar={openSidebar}
          />
        )}
      </div>

      <div className="w-full flex items-center justify-between mt-[65px] max-sm:px-3 sm:mt-4">
        <span className="text-blue text-[16px] tracking-wide font-semibold">
          Total Amount:{" "}
          <b style={{ fontSize: "18px" }}>
            ₹{formatWithCommasWithDecimal(calculateTotalAmount())}
          </b>
        </span>
        {preorderCart && preorderCart?.length > 0 && (
          <Button
            variant="Outlined"
            sx={{
              height: "35px",
              border: "2px solid #ba3737",
              borderRadius: "4px",
              padding: "5px 8px",
              fontSize: "14px",
              fontWeight: "700",
              textTransform: "none",
              color: "#ba3737",
              "&:hover": {
                border: "2px solid #ba3737",
              },
            }}
            onClick={handleRemoveAllFromPreorderCarts}
          >
            Remove all items
          </Button>
        )}
      </div>

      <div className="w-full flex flex-col mt-4 mb-[60px] sm:mb-4">
        {isMobile ? (
          <PreorderMobileCard
            preorderCart={preorderCart}
            userDetail={userDetail}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        ) : (
          <PreorderCard
            preorderCart={preorderCart}
            userDetail={userDetail}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
}

export default MyPreOrder;
