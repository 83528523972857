import React from "react";
import "./ScrollPromices.css";
import StarIcon from "@mui/icons-material/Star";
import { services } from "../../data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

function ScrollPromices() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    vertical: false,
    arrows: false,
    prevArrow: <></>,
    nextArrow: <></>,
  };
  return (
    <div className="scroll-promices-container">
      <Slider {...settings}>
        {services.map((data, index) => (
          <div className="scroll-promices-each-div" key={index}>
            <StarIcon />
            <div className="scroll-promices-main-div">
              <img loading="lazy" src={data.icon} alt="" />
              <span>{data.heading}</span>
            </div>
            <StarIcon />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ScrollPromices;

