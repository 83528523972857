import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import cyclegif from "../../img/signup/cyclegif.gif";

const WaitingGateway = () => {
    const [time, setTime] = useState(300);

    useEffect(() => {
      const interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secondsRemaining = seconds % 60;
      return `${minutes}:${secondsRemaining < 10 ? "0" : ""}${secondsRemaining}`;
    };
    return (
      <div className="w-[900px] h-[600px] bg-white-1 rounded-md p-4 relative max-sm:w-screen max-sm:h-screen max-sm:p-2">
       <div className="w-full h-full flex flex-col items-center justify-center relative">
        <span className="red-line-window text-center text-sm max-sm:text-[10px]">
          PLEASE DO NOT REFRESH OR CLOSE THIS WINDOW WHILE PAYMENT
        </span>
        <LazyLoadImage
          src={cyclegif}
          alt="Payment Trail"
          effect="blur"
          wrapperProps={{
            style: { transitionDelay: "1s" },
          }}
          className="h-[150px] object-contain mb-8"
        />
        <span className="text-[14px] text-black-1/80 tracking-wide my-2 text-center max-sm:text-[12px]">
          Your Session will expire on <b>{formatTime(time)}</b> minutes
        </span>
        <span className="text-[14px] text-black-1/80 tracking-wide my-2 text-center max-sm:text-[12px]">
          Please wait while we redirect you to the payment gateway.
        </span>
        <span className="text-[14px] text-black-1/80 tracking-wide my-2 text-center max-sm:text-[12px]">
          Please do not Refresh or Close the window.
        </span>
      </div>
      </div>
    );
}

export default WaitingGateway