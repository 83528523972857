import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
} from "@mui/material";
import { formatDate } from "../../utils/utils";
import { generateFullPaymentUrl } from "../../utils/payment";
import PaymentMain from "../Payment/PaymentMain";

const RecentOrders = ({ recentOrder, userDetail }) => {
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [openPaymentModel, setOpenPaymentModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [amount, setAmount] = useState(null);

  const getStatusColor = (status) => {
    if (status === "Pending") {
      return "#ff9800";
    } else {
      return "#28a447";
    }
  };

  const handleViewOrder = (order_ID) => {
    window.open(`/user/orders/view_order/${order_ID}`, "_blank");
  };

  const TargetFullPayment = async (orderNo, amt) => {
    await setOrderNumber(orderNo);
    await setAmount(amt);
    await generateFullPaymentUrl(
      userDetail,
      orderNo,
      setPaymentSessionId,
      setTransactionId,
      setOpenPaymentModal
    );
  };
  return (
    <>
      <div className="w-full flex flex-col bg-white-1 shadow-sm shadow-black-1/10 mb-2 rounded-md  py-2">
        <div className="w-full h-[50px] pb-1 flex flex-col border-b-[1.5px] border-black-1/10 px-3">
          <span className="text-[18px] text-black-1 font-bold tracking-wide mb-1">
            Recent Orders
          </span>
          <span className="text-[12px] text-black-1 font-medium tracking-wide">
            List of orders that you have purchased using credit line
          </span>
        </div>
        {recentOrder && recentOrder?.length > 0 ? (
          <div className="w-full px-3 overflow-x-auto">
            <TableContainer sx={{ width: "100%" }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow sx={{ textAlign: "center" }}>
                    <TableCell
                      sx={{
                        width: "16.66%",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Order ID
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "16.66%",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "16.66%",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Credit Taken
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "16.66%",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "16.66%",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "16.66%",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentOrder &&
                    recentOrder?.map((order) => (
                      <TableRow key={order?.transactionID}>
                        <TableCell
                          sx={{
                            color: "#28a447",
                            fontWeight: "bold",
                            textAlign: "center",
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          {order?.order_no}
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          {formatDate(order?.transactionDate)}
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          {order?.creditTaken}
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          {order?.amount}
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: getStatusColor(
                                  order?.transactionType === "PAYMENT"
                                    ? "Completed"
                                    : "Pending"
                                ),
                                marginRight: 5,
                                whiteSpace: "nowrap",
                              }}
                            >
                              •{" "}
                              {order?.transactionType === "PAYMENT"
                                ? "Completed"
                                : "Pending"}
                            </span>
                          </Box>
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            border: "none",
                          }}
                        >
                          {order?.transactionType === "SPEND" ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  height: "25px",
                                  fontSize: "12px",
                                  borderRadius: "30px",
                                  textTransform: "none",
                                  backgroundColor: "#28a447",
                                  whiteSpace: "nowrap",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#28a447",
                                  },
                                }}
                                onClick={() =>
                                  TargetFullPayment(
                                    order?.order_no,
                                    order?.amount
                                  )
                                }
                              >
                                Pay Now
                              </Button>
                              {/* <span className="mt-1 text-[8.5px] font-medium text-blue">
                              1% Discount,Valid til{" "}
                              {formatShortDate(order?.transactionDate)}
                            </span> */}
                            </Box>
                          ) : (
                            <Button
                              variant="outlined"
                              sx={{
                                height: "25px",
                                fontSize: "12px",
                                borderRadius: "30px",
                                textTransform: "none",
                                borderColor: "#283248",
                                color: "#283248",
                                whiteSpace: "nowrap",
                                "&:hover": {
                                  borderColor: "#283248",
                                  color: "#283248",
                                },
                              }}
                              onClick={() => handleViewOrder(order?.order_no)}
                            >
                              View Order
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <span className="w-full text-black-1 flex-center sm:h-[200px] p-2 py-5 text-[13px] tracking-wide text-center font-medium">
            No Order History found!
          </span>
        )}
      </div>
      {openPaymentModel && (
        <PaymentMain
          openPaymentModel={openPaymentModel}
          setOpenPaymentModal={setOpenPaymentModal}
          transactionId={transactionId}
          paymentSessionId={paymentSessionId}
          userDetail={userDetail}
          orderNumber={orderNumber}
          amount={amount}
        />
      )}
    </>
  );
};

export default RecentOrders;
