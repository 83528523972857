import React, { useState } from "react";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { connect } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import websiteLogo from "../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import EmailIcon from "@mui/icons-material/Email";
import {
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
} from "../../store/slices/userSlice";
import { toggleOverlay } from "../../store/slices/headerSlice";
import { setToken } from "../../features/auth/authSlice";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../config";
import promises1 from "../../img/banners/promise-1.png";
import promises2 from "../../img/banners/promise-2.png";
import promises3 from "../../img/banners/promise-3.png";
import promises4 from "../../img/banners/promise-4.png";
import MobileOTPVerification from "./MobileOTPVerification";
import NearbyStore from "../../components/ProductDescription/NearbyStore";
import toast from "react-hot-toast";

function MobileAuthentication({
  user,
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
}) {
  const { loginMode, phoneNumber } = user;

  const [open, setOpen] = React.useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  const [openStore, setOpenStore] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handletoggle = () => {
    if (loginMode === "login") {
      setLoginMode("signup");
      setPhoneNumber(null);
    } else {
      setLoginMode("login");
      setPhoneNumber(null);
    }
  };

  const handleSendOTP = async (event) => {
    handleOpen();
    event.preventDefault();
    const formData = new FormData(event.target);
    const phoneNumber = formData.get("phoneNumber");
    if (loginMode === "login") {
      try {
        const response = await fetch(
          `${API_BASE_URL}login_number/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setPhoneNumber(phoneNumber);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}phone_auth/${phoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setPhoneNumber(phoneNumber);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error('OTP not Sent')
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error('Please try again later');
      }
    }

    handleClose();
  };
  const handlePhoneNumberExist = async (phone) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}singup_phone_verify/${phone}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setPhoneExists(true);
      } else {
        setPhoneExists(false);
      }
    } catch (error) {
      console.error("Error checking phone number existence:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length === 10
    ) {
      handlePhoneNumberExist(value);
    } else if (
      loginMode === "signup" &&
      name === "phoneNumber" &&
      value.length < 10
    ) {
      setPhoneExists(false);
    }
  };
  const handleSubmitPincode = (e) => {
    e.preventDefault();
    const pincode = e.target.elements.pincode.value;
    setOpenStore(pincode);
  };
  return (
    <div className="w-screen h-screen fixed top-0 left-0 right-0 bottom-0 z-999 p-3 bg-black-1 flex flex-col items-center justify-between">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="w-full h-[50px] flex items-center justify-between">
        <img loading="lazy" src={websiteLogo} alt="cc-logo" className="h-[40px] ml-2" />
        <div
          className="mr-2"
          onClick={() => {
            toggleOverlay();
            setPhoneNumber(null);
          }}
        >
          <CloseSharpIcon
            style={{ fontSize: "24px" }}
            className="text-white-1"
          />
        </div>
      </div>

      <div className="w-full h-[calc(50%-50px)] flex items-center justify-start flex-col">
        <h2 className="text-24 tracking-wider">
          {loginMode === "login" ? "Login" : "Onboarding"}
        </h2>
        <div className="w-full flex-center flex-col mt-5">
          {phoneNumber ? (
            <MobileOTPVerification
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          ) : (
            <form
              onSubmit={handleSendOTP}
              className="flex flex-col justify-center gap-2 mt-2"
            >
              <label
                htmlFor="phoneNumber"
                className="w-full text-left text-14 tracking-wider"
              >
                Mobile Number
              </label>
              <input
                type="number"
                id="phoneNumber"
                className="w-full h-[45px] rounded-md border-[0.82px] border-gray-500 outline-none my-1 mx-auto p-2 bg-transparent text-white-1 text-16 font-medium tracking-wider"
                placeholder="Mobile number"
                maxLength="10"
                max="9999999999"
                name="phoneNumber"
                onChange={handleInputChange}
                pattern="\d{10}"
                onKeyPress={(e) => {
                  // Allow only numbers (0-9) and backspace (8)
                  if (!/[0-9\b]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  // Limit input to 10 digits
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                  }
                }}
                required
              />
              {phoneExists && (
                <span className="text-[11px] text-gold text-right mt-0 mr-2 font-medium tracking-wide">
                  Phone number already registered with Cyclecircle
                </span>
              )}
              <button
                type="submit"
                className="w-full h-[45px] bg-[purple] text-white-1 rounded-md my-3 mx-auto mb-0 text-16 font-bold tracking-wider"
              >
                Send OTP
              </button>
            </form>
          )}
        </div>
        <div className="flex-center my-2 mx-auto">
          <p className="text-14 m-0 mr-2 text-white-1">
            {loginMode === "login"
              ? "Don't have an account"
              : "Already have an account"}
            ?
          </p>
          <span className="text-[16px] text-gold" onClick={handletoggle}>
            {loginMode === "login" ? "Sign up" : "Login"}
          </span>
        </div>
      </div>

      <div className="w-full h-[50%] flex flex-col items-center justify-start px-3">
        <div className="w-full flex flex-col items-center justify-start">
          <p className="text-14 text-white-1 font-semibold">
            Find the nearest Cyclecircle.one dealership to make your purchase
            directly.
          </p>
          <form className="w-full flex-center" onSubmit={handleSubmitPincode}>
            <input
              type="text"
              inputmode="numeric"
              name="pincode"
              className="w-[65%] h-[40px] my-2 p-2 rounded-tl-md rounded-bl-md border-none outline-none text-black-1/80 tracking-wider"
              pattern="[0-9]*"
              onKeyPress={(e) => {
                // Allow only numbers (0-9) and backspace (8)
                if (!/[0-9\b]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onInput={(e) => {
                // Limit input to 10 digits
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6);
                }
              }}
              maxLength="6"
              max="999999"
              placeholder="Enter Pincode"
              required
            />
            <button
              type="submit"
              className="w-[35%] h-[40px] p-2 rounded-tr-md rounded-br-md bg-green text-white-1 text-16 font-bold tracking-wider border-none outline-none"
            >
              Check
            </button>
          </form>
        </div>
        <div className="w-full flex items-start justify-between my-2 mx-0 py-2">
          <div className="flex flex-col items-center justify-start px-1">
            <img loading="lazy" src={promises1} alt="promise-1" className="w-[30px] mb-2" />
            <span className="w-[80px] text-12 text-white-1 font-medium text-center">
              &nbsp; Free &nbsp; Delivery
            </span>
          </div>
          <div className="flex flex-col items-center justify-start px-1">
            <img loading="lazy" src={promises4} alt="promises-4" className="w-[30px] mb-2" />
            <span className="w-[80px] text-12 text-white-1 font-medium text-center">
              Quick Tracking Info
            </span>
          </div>
          <div className="flex flex-col items-center justify-start px-1">
            <img loading="lazy" src={promises2} alt="promises-2" className="w-[30px] mb-2" />
            <span className="w-[80px] text-12 text-white-1 font-medium text-center">
              Hassle Free Return
            </span>
          </div>
          <div className="flex flex-col items-center justify-start px-1">
            <img loading="lazy" src={promises3} alt="promises-3" className="w-[30px] mb-2" />
            <span className="w-[80px] text-12 text-white-1 font-medium text-center">
              Express Delivery
            </span>
          </div>
        </div>

        <div className="w-full flex flex-col items-start justify-center mt-3">
          <p className="w-full text-center text-white-1 text-14 mb-3">
            Having trouble? Feel free to reach us.
          </p>
          <div className="w-full flex items-center justify-between my-3">
            <div className="flex items-center">
              <LocalPhoneIcon
                style={{ fontSize: "20px" }}
                className="mr-2 text-white-1"
              />
              <span className="text-white-1 whitespace-nowrap text-14">
                +91-9429693000
              </span>
            </div>
            <hr />
            <div className="flex items-center">
              <EmailIcon
                style={{ fontSize: "20px" }}
                className="mr-2 text-white-1"
              />
              <span className="text-white-1 whitespace-nowrap text-14">
                support@cyclecircle.one
              </span>
            </div>
          </div>
          <div className="w-full flex-center text-12 my-3">
            <Link to="/support" className="text-12 text-white-1 mx-2 underline">
              Privacy Policy
            </Link>{" "}
            &{" "}
            <Link to="/support" className="text-12 text-white-1 mx-2 underline">
              Terms of Service
            </Link>{" "}
            apply.
          </div>
        </div>
      </div>
      {openStore && openStore?.length === 6 && (
        <>
          <div className="w-screen h-screen fixed top-0 left-0 right-0 bottom-0 bg-black-1/60 z-1000"></div>
          <div className="w-screen h-screen fixed z-[1001] bg-white-1 top-0 left-0 bottom-0 right-0">
            <NearbyStore openStore={openStore} setOpenStore={setOpenStore} />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLoginMode,
  setPhoneNumber,
  setCodeEntered,
  setOTPVerified,
  toggleOverlay,
  setToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileAuthentication);
