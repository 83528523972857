import React, { useState, useEffect } from "react";
import "./OrderConfirmed.css";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import confirmOrderLogo from "../../img/order_confirm-logo.png";
import ccLogo from "../../img/parts/Cycle-Circle-Logo-Orange-1.png";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router";
import { fetchAllCartsAsync } from "../../features/cartPage/cartPageSlice";
import { useDispatch } from "react-redux";
import paymentFailedLogo from "../../img/payment/cancel-payment-logo.png";
import cashbackCard from "../../img/banners/cashback-card.jpg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateAmountToPay,
  calculateShippingCost,
  calculateTotalAmount,
  formatDateAndTime,
  formatWithCommasWithDecimal,
  trackSteps,
} from "../../utils/utils";
import cyclegif from "../../img/signup/cyclegif.gif";
import {
  handleFreezeQuantity,
  handleOrder,
  initiatePayment,
} from "../../utils/payment";
import { Button, Dialog } from "@mui/material";

export function PaymentFailed({ setConfirmOverlay, calculateAmountToPay }) {
  return (
    <div className="payment-failed-container">
      <LazyLoadImage
        src={paymentFailedLogo}
        alt="Payment Failed"
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: { transitionDelay: "1s" },
        }}
      />
      <span className="payment-price">
        ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
      </span>
      <span className="payment-failed-red-text">Payment Failed!</span>
      <span className="payment-failed-big-text">
        Hey it seems like there was some trouble. We are there with you. Just
        hold back.
      </span>
      <span className="payment-failed-order-id-date">
        {formatDateAndTime(new Date())}
      </span>
      <span
        className="payment-again-btn"
        onClick={() => setConfirmOverlay(false)}
      >
        Try again
      </span>
    </div>
  );
}

export function PaymentPending({ setConfirmOverlay, calculateAmountToPay }) {
  return (
    <div className="payment-pending-container">
      <LazyLoadImage
        src={paymentFailedLogo}
        alt="Payment Pending"
        effect="blur"
        wrapperProps={{
          style: { transitionDelay: "1s" },
        }}
      />
      <span className="payment-price">
        ₹{formatWithCommasWithDecimal(calculateAmountToPay())}
      </span>
      <span className="payment-pending-yellow-text">Payment Pending</span>
      <span className="payment-pending-big-text">
        Your payment is currently pending. We're here to help you.
      </span>
      <span className="payment-pending-order-id-date">
        {formatDateAndTime(new Date())}
      </span>
      <span className="support-text">
        Please reach out to our support team to confirm your order:
      </span>
      <div className="support-contact">
        <span>
          <strong>Phone:</strong> <a href="tel:+919429693000">+91-9429693000</a>
        </span>
        <span>
          <strong>Email:</strong>{" "}
          <a href="mailto:support@cyclecircle.one">support@cyclecircle.one</a>
        </span>
      </div>
      <span
        className="payment-again-btn"
        onClick={() => setConfirmOverlay(false)}
      >
        Close
      </span>
    </div>
  );
}

export function MoveToPaymentGateway({
  handleClose,
  setPlaced,
  openPlaced,
  setOpenPlaced,
}) {
  const [time, setTime] = useState(300);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          handleClose();
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = seconds % 60;
    return `${minutes}:${secondsRemaining < 10 ? "0" : ""}${secondsRemaining}`;
  };
  return (
    <div className="w-[900px] h-[600px] bg-white-1 rounded-md p-4 relative max-sm:w-screen max-sm:h-screen max-sm:p-2">
      {openPlaced && (
        <div className="w-[300px] h-[180px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white-1 z-[9999] flex-center flex-col p-3 rounded-md shadow-sm shadow-black-1/30 max-sm:w-[90%] max-sm:h-auto max-sm:p-2">
          <span className="text-[16px] tracking-wide text-black-1/80 my-5 max-sm:my-2">
            Want to place order?
          </span>
          <div className="w-[50%] flex items-center justify-between mt-5 mb-3 max-sm:w-[80%] max-sm:mt-3">
            <Button
              variant="contained"
              sx={{
                height: "30px",
                padding: "0 10px",
                borderRadius: "4px",
                background: "#ba3737",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "500",
                color: "white",
                "&:hover": {
                  background: "#ba3737",
                },
              }}
              onClick={() => {
                setPlaced(true);
                setOpenPlaced(false);
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              sx={{
                height: "30px",
                padding: "0 10px",
                borderRadius: "4px",
                border: "1.5px solid rgba(0,0,0,0.7)",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                "&:hover": {
                  border: "1.5px solid rgba(0,0,0,0.7)",
                },
              }}
              className="want-to-place-no"
              onClick={() => {
                setOpenPlaced(false);
                handleClose();
              }}
            >
              No
            </Button>
          </div>
        </div>
      )}
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <span className="red-line-window text-center text-sm max-sm:text-[10px]">
          PLEASE DO NOT REFRESH OR CLOSE THIS WINDOW WHILE PAYMENT
        </span>
        <LazyLoadImage
          src={cyclegif}
          alt="Payment Trail"
          effect="blur"
          wrapperProps={{
            style: { transitionDelay: "1s" },
          }}
          className="h-[150px] object-contain mb-8"
        />
        <span className="text-[14px] text-black-1/80 tracking-wide my-2 text-center max-sm:text-[12px]">
          Your Session will expire on <b>{formatTime(time)}</b> minutes
        </span>
        <span className="text-[14px] text-black-1/80 tracking-wide my-2 text-center max-sm:text-[12px]">
          Please wait while we redirect you to the payment gateway.
        </span>
        <span className="text-[14px] text-black-1/80 tracking-wide my-2 text-center max-sm:text-[12px]">
          Please do not Refresh or Close the window.
        </span>
      </div>
    </div>
  );
}
const OrderConfirmed = ({
  cart,
  userDetail,
  paymentSessionId,
  transactionId,
  selectedAddress,
  selectedCoupon,
  useWalletAmount,
  setUseWalletAmount,
  premUser,
  currentStep,
  setCurrentStep,
  useCreditAmount,
  setUseCreditAmount,
  generatePaymentUrl,
  openPaymentModel,
  setOpenPaymentModel,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPaymentGateway, setShowPaymentGateway] = useState(true);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [showFailedPayment, setShowFailedPayment] = useState(false);
  const [showPendingPayment, setShowPendingPayment] = useState(false);
  const [orderNo, setOrderNo] = useState(null);
  const [openPlaced, setOpenPlaced] = useState(false);
  const [placed, setPlaced] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleCloses = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (cart && cart.length > 0) {
      handleFreezeQuantity(cart, userDetail, dispatch, transactionId);
    }
  }, [dispatch, cart, userDetail, transactionId]);

  const [complete, setComplete] = useState(false);
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep || complete) {
      setCurrentStep(stepIndex);
    }
  };
  const handleClose = () => {
    const fetchData = async () => {
      handleOpen();
      await dispatch(fetchAllCartsAsync(userDetail?.UserID)).then(() => {
        navigate("/carts");
        setCurrentStep(1);
        setOpenPaymentModel(false);
        setShowOrderConfirmation(false);

        handleCloses();
      });
    };
    fetchData();
    return () => clearTimeout(2000);
  };

  // Order placed without payment if ADMIN
  useEffect(() => {
    handleOrder(
      cart,
      dispatch,
      transactionId,
      userDetail,
      selectedAddress,
      useWalletAmount,
      useCreditAmount,
      selectedCoupon,
      setOrderNo,
      setShowPaymentGateway,
      setShowOrderConfirmation,
      placed
    );
  }, [placed]);

  // Cashfree setup
  useEffect(() => {
    initiatePayment(
      userDetail,
      dispatch,
      transactionId,
      setOpenPlaced,
      setShowPaymentGateway,
      paymentSessionId,
      cart,
      selectedAddress,
      useWalletAmount,
      useCreditAmount,
      selectedCoupon,
      setOrderNo,
      setShowOrderConfirmation,
      setShowFailedPayment,
      setShowPendingPayment,
      setUseWalletAmount,
      setUseCreditAmount
    );
  }, [dispatch, paymentSessionId, transactionId, userDetail]);

  return (
    <Dialog
      open={openPaymentModel}
      fullWidth={true}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          width: "100vw",
          height: "100vh",
          margin: 0,
          maxWidth: "100vw",
          maxHeight: "100vh",
        },
        "@media (min-width: 600px)": {
          "& .MuiDialog-paper": {
            width: "auto",
            height: "auto",
          },
        },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleCloses}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showPaymentGateway && (
        <MoveToPaymentGateway
          handleClose={handleClose}
          setPlaced={setPlaced}
          openPlaced={openPlaced}
          setOpenPlaced={setOpenPlaced}
        />
      )}
      {!showPaymentGateway &&
        !showOrderConfirmation &&
        !showFailedPayment &&
        !showPendingPayment && (
          <div
            id="cashfree-payment"
            style={{ borderRadius: "10px" }}
            // className="order-confirm-container"
          ></div>
        )}
      {showOrderConfirmation && (
        <div className="w-[900px] h-[600px] flex max-sm:flex-col max-sm:w-screen max-sm:h-screen items-center justify-between bg-white-1 rounded-md p-4 relative max-sm:mb-5">
          <CloseSharpIcon
            className="absolute top-3 left-3 text-black-1 cursor-pointer flex z-999"
            onClick={handleClose}
            style={{ fontSize: "18px" }}
          />
          <div className="w-full sm:w-[60%] h-full flex flex-col items-center sm:pr-4 max-sm:pt-4">
            <div className="w-full h-[40%] py-[30px] px-[100px] flex flex-col items-center justify-between">
              <LazyLoadImage
                src={confirmOrderLogo}
                alt=""
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "1s" },
                }}
                className="w-[120px] sm:w-[150px] object-contain"
              />
              <span className="text-[17px] uppercase text-black-1 max-sm:my-3">
                Thank You
              </span>
              <span className="text-[14px] font-bold text-black-1/80">
                Your order is confirmed
              </span>
            </div>
            <div className="w-full rounded-md shadow-sm shadow-black-1/20 py-3 px-2 sm:px-4 bg-white-1">
              <span className="text-[12px] text-black-1/70">
                Order code <b>#{orderNo}</b> was placed on{" "}
                <b>{`${new Date().getDate()}-${
                  new Date().getMonth() + 1
                }-${new Date().getFullYear()}`}</b>{" "}
                and is currently in progress
              </span>
              <div className="w-full py-4 rs-flex-box-grid-center text-gray-700 bg-white-1 flex">
                {trackSteps?.map((step, i) => (
                  <div
                    key={i}
                    className={`step-item relative flex-center flex-col w-full ${
                      currentStep === i + 1 && "active"
                    } ${i + 1 < currentStep && "complete"} `}
                    onClick={() => handleStepClick(i + 1)}
                  >
                    <div
                      className={`step w-[22px] sm:w-[25px] h-[22px] sm:h-[25px] p-1 flex-center z-1 relative rounded-full font-semibold ${
                        currentStep > i + 1
                          ? "text-white-1 bg-green"
                          : "text-blue bg-gray-200"
                      }`}
                    >
                      {step.icon}
                    </div>
                    <p
                      className={`mt-1 text-[10px] sm:text-[11px] md:text-[12px] text-center ${
                        currentStep > i + 1 ? "text-green" : "text-blue"
                      }`}
                    >
                      {step.label}
                    </p>
                  </div>
                ))}
              </div>
              <span className="text-[12px] text-black-1/70">
                Exprected delivery date:{" "}
                <b>
                  {" "}
                  {new Date(
                    new Date().getTime() + 5 * 24 * 60 * 60 * 1000
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </b>
              </span>
            </div>
            <span className="text-[12px] my-3 text-center text-black-1/80">
              We will be sending you an email confirmation to{" "}
              <b>{userDetail && userDetail?.Email}</b>
            </span>
            <div className="w-full py-[30px] sm:px-5 flex items-center justify-between">
              <Button
                variant="contained"
                onClick={() => navigate("/")}
                sx={{
                  height: "35px",
                  background: "#283248",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "4px",
                  color: "white",
                  padding: "5px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "none",
                  "&:hover": {
                    background: "#283248",
                  },
                }}
              >
                <ChevronLeftSharpIcon
                  className="text-white-1 mr-2"
                  style={{ fontSize: "16px" }}
                />
                Continue to shopping
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate("/user/orders")}
                sx={{
                  height: "35px",
                  background: "#28a447",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "4px",
                  color: "white",
                  padding: "5px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "none",
                  "&:hover": {
                    background: "#28a447",
                  },
                }}
              >
                <RemoveRedEyeSharpIcon
                  className="text-white-1 mr-2"
                  style={{ fontSize: "16px" }}
                />
                View my orders
              </Button>
            </div>
          </div>
          <div className="w-full sm:w-[40%] h-full shadow-sm shadow-black-1/20 rounded-md flex flex-col relative">
            <LazyLoadImage
              src={ccLogo}
              alt="cc-logo"
              className="absolute top-2 left-1/2 -translate-x-1/2 h-[50px]"
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "1s" },
              }}
            />
            <div className="w-full h-[44%] px-3 pt-[80px] relative">
              <LazyLoadImage
                src={cashbackCard}
                alt=""
                className="w-full h-full object-fill rounded-md"
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "1s" },
                }}
              />
              <span className="w-full text-white-1 text-[11px] absolute bottom-[25%] left-1/2 -translate-x-1/2 text-center">
                Please Click below to check your updated wallet
              </span>
              <Button
                variant="contained"
                sx={{
                  height: "30px",
                  background: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#283248",
                  borderRadius: "30px",
                  padding: "0 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "none",
                  position: "absolute",
                  left: "50%",
                  bottom: "10%",
                  transform: "translateX(-50%)",
                  "&:hover": {
                    background: "white",
                  },
                }}
                onClick={() => navigate("/user/wallet")}
              >
                View My Wallet
              </Button>
            </div>
            <div className="w-full h-[56%] flex items-center flex-col justify-end p-4">
              <h3 className="text-[15px] text-blue text-center my-2 font-bold">
                Order Summary
              </h3>
              <div className="w-full flex items-center justify-between py-[6px]">
                <span className="text-[13px] text-blue">Sub Total</span>
                <span className="text-[14px] text-blue">
                  ₹{formatWithCommasWithDecimal(calculateTotalAmount(cart))}
                </span>
              </div>
              <div className="w-full flex items-center justify-between py-[6px]">
                <span className="text-[13px] text-blue">Delivery</span>
                <span className="text-[14px] text-blue">
                  ₹{formatWithCommasWithDecimal(calculateShippingCost(cart))}
                </span>
              </div>

              <div className="w-full flex items-center justify-between py-[6px]">
                <span className="text-[13px] text-blue">Discount</span>
                <span className="text-[14px] text-blue">
                  -₹
                  {formatWithCommasWithDecimal(
                    selectedCoupon?.couponDiscountTaken
                  )}
                </span>
              </div>
              <div className="w-full flex items-center justify-between py-[6px]">
                <span className="text-[13px] text-blue">Wallet</span>
                <span className="text-[14px] text-blue">
                  -₹
                  {formatWithCommasWithDecimal(useWalletAmount)}
                </span>
              </div>
              <div className="w-full flex items-center justify-between py-[6px]">
                <span className="text-[13px] text-blue">Line of Credit</span>
                <span className="text-[14px] text-blue">
                  -₹
                  {formatWithCommasWithDecimal(useCreditAmount)}
                </span>
              </div>
              <div className="w-full flex items-center justify-between py-[6px]">
                <span className="text-[14px] text-blue font-bold">Total</span>
                <span className="text-[14px] text-blue font-bold">
                  ₹
                  {formatWithCommasWithDecimal(
                    parseInt(
                      calculateAmountToPay(
                        cart,
                        selectedCoupon?.couponDiscountTaken,
                        useWalletAmount,
                        useCreditAmount,
                        setUseWalletAmount,
                        setUseCreditAmount
                      )
                    ).toFixed(2)
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};
export default OrderConfirmed;
