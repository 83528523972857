import React, { useState } from "react";
import "./MobileAdminDashboard.css";
import { useNavigate } from "react-router";
import MobileAdminPreOrder from "./MobileAdminPreOrder";
import MobileAdminOrders from "./MobileAdminOrders";
import MobileAdminCarts from "./MobileAdminCarts";
import MobileAdminToggle from "./MobileAdminToggle";
import MobileAdminShipping from "./MobileAdminShipping";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import {
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import adminLogo from "../../assets/adminLogo.svg";
import preorderLogo from "../../assets/Icon-preorder.svg";
import orderLogo from "../../assets/Icon-orders.svg";
import shippingLogo from "../../assets/shippingLogo.svg";
import lineOfCreditLogo from "../../assets/Icon-LOC1.svg";
import cartLogo from "../../assets/cartLogo.svg";
import AdminLOC from "../../components/AdminDashboard/AdminLOC";

function MobileAdminDashboard() {
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const [nav, setNav] = useState("pre-orders");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderContent = () => {
    switch (nav) {
      case "pre-orders":
        return <MobileAdminPreOrder />;
      case "orders":
        return <MobileAdminOrders />;
      case "carts":
        return <MobileAdminCarts />;
      case "toggle":
        return <MobileAdminToggle />;
        case "credit":
        return <AdminLOC />;
      case "shipping":
        return <MobileAdminShipping />;
      default:
        return <MobileAdminPreOrder />;
    }
  };
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="mobile-admin-dashboard-v2-container">
      <AppBar position="fixed" className="mobile-admin-dashboard-header-v2">
        <Toolbar className="mobile-admin-dashboard-header-v2">
          <img loading="lazy"
            src={cyclecircleLogo}
            alt="cc-logo"
            className="mobile-admin-dashboard-cc-logo-v2"
            onClick={() => navigate("/")}
          />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            className="hamburger-menu-icon"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (Sidebar) */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        sx={{ "& .MuiDrawer-paper": { width: "55vw" } }}
        className="mobile-admin-drawer-v2"
      >
        <div className="sidebar-header">
          <Avatar sx={{ width: 56, height: 56, margin: "5px auto" }}>
            <img loading="lazy"
              src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                userDetail?.UserImage || ""
              }`}
              alt={userDetail?.UserImage || ""}
              style={{ width: "100%" }}
            />
          </Avatar>
          <h3 style={{ textAlign: "center", margin: "5px 0" }}>
            {userDetail?.FirstName}&nbsp;{userDetail?.LastName}
          </h3>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              setNav("pre-orders");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "pre-orders" ? "var(--color-blue)" : "inherit",
              color: nav === "pre-orders" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "pre-orders" ? "white" : "inherit",
              }}
            >
              <img loading="lazy"
              src={preorderLogo}
              alt="preorder-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{filter:nav === "pre-orders" ? "invert(100%)":""}}
            />
            </ListItemIcon>
            <ListItemText
              primary="Pre-Orders"
              className="list-item-text-mobile"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("orders");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "orders" ? "var(--color-blue)" : "inherit",
              color: nav === "orders" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "orders" ? "white" : "inherit",
              }}
            >
              <img loading="lazy"
              src={orderLogo}
              alt="order-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{filter:nav === "orders" ? "invert(100%)":""}}
            />
            </ListItemIcon>
            <ListItemText primary="Orders" className="list-item-text-mobile" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("carts");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "carts" ? "var(--color-blue)" : "inherit",
              color: nav === "carts" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "carts" ? "white" : "inherit",
              }}
            >
              <img loading="lazy"
              src={cartLogo}
              alt="cart-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{filter:nav === "carts" ? "invert(100%)":""}}
            />
            </ListItemIcon>
            <ListItemText primary="Carts" className="list-item-text-mobile" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("toggle");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "toggle" ? "var(--color-blue)" : "inherit",
              color: nav === "toggle" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "toggle" ? "white" : "inherit",
              }}
            >
              <img loading="lazy"
              src={adminLogo}
              alt="admin-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{filter:nav === "toggle" ? "invert(100%)":""}}
            />
            </ListItemIcon>
            <ListItemText
              primary="Switch to Admin"
              className="list-item-text-mobile"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("shipping");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "shipping" ? "var(--color-blue)" : "inherit",
              color: nav === "shipping" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "shipping" ? "white" : "inherit",
              }}
            >
              <img loading="lazy"
              src={shippingLogo}
              alt="shipping-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{filter:nav === "shipping" ? "invert(100%)":""}}
            />
            </ListItemIcon>
            <ListItemText
              primary="Free Shipping"
              className="list-item-text-mobile"
              
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNav("credit");
              handleDrawerToggle();
            }}
            sx={{
              backgroundColor:
                nav === "credit" ? "var(--color-blue)" : "inherit",
              color: nav === "credit" ? "white" : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color: nav === "credit" ? "white" : "inherit",
              }}
            >
              <img loading="lazy"
              src={lineOfCreditLogo}
              alt="loc-logo"
              width={25}
              height={25}
              className="mr-2"
              style={{filter:nav === "credit" ? "invert(100%)":""}}
            />
            </ListItemIcon>
            <ListItemText
              primary="Line of Credit"
              className="list-item-text-mobile"
            />
          </ListItem>
        </List>
      </Drawer>

      <div className="mobile-admin-dashboard-content">
        <h3 className="mobile-admin-dashboard-content-heading">Admin {nav}</h3>
        {renderContent()}
      </div>
    </div>
  );
}

export default MobileAdminDashboard;
