import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPhoneNumber,
  selectSaveData,
  signUpAsync,
} from "../../../features/auth/authSlice";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../../config";
import MobileContactInfo from "./MobileContactInfo";
import MobileBasicInfo from "./MobileBasicInfo";
import MobileBusinessPAN from "./MobileBusinessPAN";
import MobileBusinessRegistration from "./MobileBusinessRegistration";
import MobileBusinessOwner from "./MobileBusinessOwner";
import MobileSignUpSideBar from "./MobileSignUpSideBar";
import { OnboardingSuccess } from "../../../mComponent/OTPVerification";
import { Button } from "@mui/material";
import toast from "react-hot-toast";

function MobileSignUp() {
  const dispatch = useDispatch();
  const saveData = useSelector(selectSaveData);
  const [emailVerify, setEmailVerify] = useState(
    saveData && saveData?.Email ? true : false
  );
  const [phoneVerify, setPhoneVerify] = useState(true);
  const [PANVerify, setPANVerify] = useState(false);
  const [GSTVerify, setGSTVerify] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const [herebyDeclare, setHerebyDeclare] = useState(false);
  const [complete, setComplete] = useState(false);
  const phoneNumber = useSelector(selectPhoneNumber);
  const [currentStep, setCurrentStep] = useState(0);
  const [tempEmail, setTempEmail] = useState(
    (saveData && saveData?.Email) || null
  );
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [finalData, setFinalData] = useState({
    Email: (saveData && saveData?.Email) || null,
    PhoneNumber: (phoneNumber && phoneNumber) || null,
    ReceiveWhatsApp: (saveData && saveData?.ReceiveWhatsApp) || false,
    FirstName: (saveData && saveData?.FirstName) || null,
    LastName: (saveData && saveData?.LastName) || null,
    Gender: null,
    DOB: (saveData && saveData?.DOB) || null,
    USER_IMAGE: (saveData && saveData?.USER_IMAGE) || null,
    pin_code: (saveData && saveData?.pin_code) || null,
    location: (saveData && saveData?.location) || null,
    district: (saveData && saveData?.district) || null,
    state_id: (saveData && saveData?.state_id) || null,
    address_line_1: (saveData && saveData?.address_line_1) || null,
    address_line_2: (saveData && saveData?.address_line_2) || null,
    save_address: (saveData && saveData?.save_address) || "Dealership",
    Password: "123",
    pan_card: (saveData && saveData?.pan_card) || null,
    Have_GSTIN: (saveData && saveData?.Have_GSTIN) || "yes",
    business_name: (saveData && saveData?.business_name) || null,
    applicable: (saveData && saveData?.applicable) || "1",
    aadhar: (saveData && saveData?.aadhar) || null,
    GST: (saveData && saveData?.GST) || null,
    business_document:
      (saveData && saveData?.business_document) || "Tax Return",
    Document_IMAGE: (saveData && saveData?.Document_IMAGE) || null,
    GST_DATA: null,
    Aadhar_DATA: null,
    PAN_DATA: null,
  });

  useEffect(() => {
    if (saveData && saveData?.Email && saveData?.Email === finalData?.Email) {
      setEmailVerify(true);
    } else {
      setEmailVerify(false);
    }
  }, [saveData, finalData]);

  // Validation states for required fields
  const fieldRequired = [
    ["Email", "PhoneNumber", "emailVerify", "phoneVerify", "ReceiveWhatsApp"],
    [
      "FirstName",
      "LastName",
      "Gender",
      "DOB",
      "pin_code",
      "address_line_1",
      "location",
      "district",
      "state_id",
      "save_address",
    ],
    ["pan_card", "PANVerify"],
    ["Have_GSTIN", "business_name", "applicable"],
    ["aadhar", "aadharVerify", "herebyDeclare"],
    ["business_document", "document_IMAGE"],
  ];

  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
  };
  const steps = [
    {
      s: 0,
      name: "Contact Information",
      component: (
        <MobileContactInfo
          updateFinalData={updateFinalData}
          finalData={finalData}
          emailVerify={emailVerify}
          setEmailVerify={setEmailVerify}
          phoneVerify={phoneVerify}
          setPhoneVerify={setPhoneVerify}
          tempEmail={tempEmail}
          setTempEmail={setTempEmail}
        />
      ),
    },
    {
      s: 1,
      name: "Personal & Shipping Details",
      component: (
        <MobileBasicInfo
          updateFinalData={updateFinalData}
          finalData={finalData}
        />
      ),
    },
    {
      s: 2,
      name: "Business PAN",
      component: (
        <MobileBusinessPAN
          updateFinalData={updateFinalData}
          finalData={finalData}
          PANVerify={PANVerify}
          setPANVerify={setPANVerify}
        />
      ),
    },
    {
      s: 3,
      name: "Business Registration Details",
      component: (
        <MobileBusinessRegistration
          updateFinalData={updateFinalData}
          finalData={finalData}
          GSTVerify={GSTVerify}
          setGSTVerify={setGSTVerify}
        />
      ),
    },
    {
      s: 4,
      name: "Business Owner Details",
      component: (
        <MobileBusinessOwner
          updateFinalData={updateFinalData}
          finalData={finalData}
          aadharVerify={aadharVerify}
          setAadharVerify={setAadharVerify}
          setHerebyDeclare={setHerebyDeclare}
          herebyDeclare={herebyDeclare}
        />
      ),
    },
  ];

  // Function to check if all required fields of the current step are filled
  const isStepValid = () => {
    const fields = fieldRequired[currentStep];
    for (const key in fields) {
      if (finalData.hasOwnProperty(fields[key])) {
        if (finalData[fields[key]] === null || finalData[fields[key]] === "") {
          return false;
        }
      } else {
        if (
          (fields[key] === "emailVerify" && !emailVerify) ||
          (fields[key] === "phoneVerify" && !phoneVerify) ||
          (fields[key] === "PANVerify" && !PANVerify) ||
          (fields[key] === "GSTVerify" && !GSTVerify) ||
          (fields[key] === "aadharVerify" && !aadharVerify) ||
          (fields[key] === "herebyDeclare" && !herebyDeclare)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const validData = async () => {
    const sendData = {
      phoneNumber: finalData?.PhoneNumber || "",
      updatedAttributes: {
        FirstName: finalData?.FirstName || "",
        LastName: finalData?.LastName || "",
        Email: finalData?.Email || "",
        Gender: finalData?.Gender || "",
        DOB: finalData?.DOB || "",
        ReceiveWhatsApp: finalData?.ReceiveWhatsApp || false,
        business_name: finalData?.business_name || "",
        address_line_1: finalData?.address_line_1 || "",
        address_line_2: finalData?.address_line_2 || "",
        pin_code: finalData?.pin_code || "",
        location: finalData?.location || "",
        district: finalData?.district || "",
        save_address: finalData?.save_address || "Dealership",
        state_id: finalData?.state_id || 0,
        GST: finalData?.GST || "",
        aadhar: finalData?.aadhar || "",
        pan_card: finalData?.pan_card || "",
        Have_GSTIN: finalData?.Have_GSTIN || "yes",
        applicable: finalData?.applicable || "1",
      },
    };
    try {
      // Call the API
      const response = await fetch(`${API_BASE_URL}cache_data`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendData),
      });

      if (response.ok) {
      } else {
        console.error("Cache data API call failed");
      }
    } catch (error) {
      console.error("Error calling cache data API:", error);
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = async () => {
    if (currentStep < steps.length - 1 && isStepValid()) {
      await validData();
      setCurrentStep(currentStep + 1);
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      return;
    }
    // Check if the form is valid and navigate accordingly
    if (currentStep < steps.length - 1 && isStepValid()) {
      await validData();
      setCurrentStep(currentStep + 1);
      return;
    }
    if (currentStep === steps.length - 1 && isStepValid()) {
      handleOpen();
      try {
        const response = await dispatch(signUpAsync(finalData));

        if (response?.payload) {
          // If the response is successful (status 200-299)
          handleClose();
          setComplete(true);
          return;
        } else {
          handleClose();
          toast.error('Signup failed');
        }
      } catch (error) {
        handleClose();
        console.log("Error signing up:", error);
        // Close the loading modal
        handleClose();

        // Show an error message using SweetAlert if the sign-up fails
        toast.error('Signup Failed')
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form className="w-screen h-screen" onSubmit={handleSubmit}>
        <div className="w-full h-full bg-white-1 relative">
          <div className="w-full h-full flex flex-col items-start justify-start flex-1 relative">
            <div className="w-full fixed left-0 top-0 z-10 bg-white-1 shadow-md shadow-black-1/20">
              <MobileSignUpSideBar currentStep={currentStep} steps={steps} />
            </div>
            <div className="flex-1 relative flex flex-col mt-[100px]">
              <div className="w-full overflow-y-auto">
                {steps[currentStep].component}
              </div>
            </div>
            <div className="w-full border-t-2 border-black-1/10 py-[10px] px-3 fixed bottom-0 left-0 right-0 flex items-center justify-between z-1 bg-white-1">
              <span
                className="text-14 font-bold text-black-1/80 tracking-wider"
                onClick={handleBack}
              >
                {currentStep > 0 ? "Back" : ""}
              </span>
              <Button
                variant="contained"
                className="w-auto text-14 py-[5px] px-2 rounded-sm text-white-1 font-bold tracking-wide outline-none border-none flex items-center"
                style={{
                  background: isStepValid()
                    ? "var(--color-green)"
                    : "rgba(0,0,0,0.1)",
                  color: isStepValid() ? "white" : "rgba(0,0,0,0.8)",
                }}
                onClick={handleNextStep} // Add this click handler
                type={currentStep === steps.length - 1 ? "submit" : "button"}
              >
                {currentStep === steps.length - 1
                  ? "Submit"
                  : steps[currentStep + 1].name}
                <ArrowForwardIcon
                  style={{ fontSize: "22px", marginLeft: "5px" }}
                />
              </Button>
            </div>
          </div>
        </div>
      </form>
      {complete && <OnboardingSuccess open={complete} />}
    </>
  );
}

export default MobileSignUp;
