import React, { useEffect, useState } from "react";
import "./MobileAdminPreOrder.css";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CloseIcon from "@mui/icons-material/Close";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateGST,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
  handleCurrentPrice,
} from "../../utils/utils";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import NoCartItem from "../../components/Carts/NoCartItem";
import PreorderMobileCard from "../../dComponent/Preorder/PreorderMobileCard";
import Fulfilled from "../../dComponent/Fullfilled";
import { AdminRemoveFromPreorders } from "../../utils/admin";

function MobileAdminPreOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const userPreBook = useSelector(selectAllPreordersUser);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(userPreBook);
  const [openAccordian, setOpenAccordian] = useState(null);
  const handleAccordian = (index) => {
    if (openAccordian !== index) {
      setOpenAccordian(index);
    } else {
      setOpenAccordian(null);
    }
  };
  const [openOrder, setOpenOrder] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID)).then(
        () => {
          handleClose();
        }
      );
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (userPreBook) {
      setFilteredUsers(
        userPreBook?.filter(
          (user) =>
            user?.FirstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.LastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.PhoneNumber.includes(searchQuery)
        )
      );
    }
  }, [searchQuery, userPreBook]);

  const wantToFulfilled = (id) => {
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(fFilled)).then(() => {
      dispatch(fetchAllPreOrderUserDetailsAsync(fFilled));
      setFFilled(null);
      handleClose();
    });
  };
  const handleFulfilled = (id) => {
    setFFilled(id);
  };

  const calculateTotalAmount = (user) => {
    let totalAmount = 0;

    if (user && user?.Preorders) {
      for (const preorder of user?.Preorders) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  const calculateOverallAmount = () => {
    let overallAmount = 0;

    if (userPreBook && userPreBook?.length > 0) {
      for (const user of userPreBook) {
        if (user && user?.Preorders) {
          for (const preorder of user?.Preorders) {
            // Choose the price: offer price if available, otherwise NDP price
            const price = preorder?.Offer_price
              ? calculateOfferPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.Offer_price),
                  preorder?.GST
                )
              : calculateDealerPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.NDP_price),
                  preorder?.GST
                );

            // Calculate the amount for this preorder item
            const amount = price * preorder?.quantity;

            // Add to the total amount
            overallAmount += amount;
          }
        }
      }
    }
    return overallAmount;
  };
  return (
    <div className="mobile-admin-preorder-v2-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-admin-dashboard-middle-container">
        <div className="mobile-admin-dashboard-middle-search-container">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by name or phone"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mobile-admin-dashboard-search-input"
          />
        </div>
        <div className="mobile-admin-dashboard-total-preorder-container">
          <span className="mobile-admin-dashboard-heading-total">
            Total Pre-Orders
          </span>
          <span className="mobile-admin-dashboard-total-count">
            {userPreBook && Array.isArray(userPreBook)
              ? userPreBook?.length
              : 0}
          </span>
          <WorkHistoryOutlinedIcon />
        </div>
      </div>
      {filteredUsers && Array.isArray(filteredUsers) ? (
        <div className="mobile-admin-dashboard-main-user-preorder-container">
          <div className="mobile-admin-dashboard-main-user-preorder-header">
            <span className="mobile-admin-dashboard-main-user-preorder-heading">
              Pre-Orders
            </span>
            <span className="mobile-admin-dashboard-main-user-preorder-overall">
              Overall Amount:
              <b style={{ fontSize: "14px" }}>
                {" "}
                ₹{formatWithCommasWithDecimal(calculateOverallAmount())}
              </b>
            </span>
          </div>
          <div className="mobile-admin-dashboard-main-user-item-head">
            <span className="mobile-admin-dashboard-serial-no">S.No</span>
            <span className="mobile-admin-dashboard-item-each-head">
              User Name
            </span>
            <span className="mobile-admin-dashboard-item-each-head">
              Phone No
            </span>
            <span className="mobile-admin-dashboard-item-each-head">Order</span>
            <span className="mobile-admin-dashboard-item-dot">-</span>
          </div>
          <div className="mobile-admin-dashboard-main-user-prebook-container">
            {filteredUsers &&
              Array.isArray(filteredUsers) &&
              filteredUsers?.map((user, index) => (
                <div
                  className="mobile-admin-dashboard-main-each-user"
                  key={index}
                >
                  <div
                    className="mobile-admin-dashboard-main-each-user-top"
                    style={{
                      boxShadow:
                        user?.UserID === openOrder &&
                        "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                    }}
                    key={index}
                  >
                    <span className="mobile-admin-dashboard-user-sno">
                      {index + 1}
                    </span>
                    <span className="mobile-admin-user-each-attr">
                      {user?.FirstName}
                    </span>
                    <span className="mobile-admin-user-each-attr">
                      {user?.PhoneNumber}
                    </span>
                    <span className="mobile-admin-user-each-attr">
                      ₹{formatWithCommasWithDecimal(calculateTotalAmount(user))}
                      {openOrder === user?.UserID ? (
                        <KeyboardArrowDownOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(null)}
                        />
                      ) : (
                        <ChevronRightOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenOrder(user?.UserID)}
                        />
                      )}
                    </span>
                    <span
                      className="mobile-admin-user-req-fulfilled"
                      onClick={() => handleFulfilled(user?.UserID)}
                    >
                      ?
                    </span>
                    {fFilled !== null && (
                      <Fulfilled
                        fFilled={fFilled}
                        handleFulfilled={handleFulfilled}
                        wantToFulfilled={wantToFulfilled}
                        user={user}
                      />
                    )}
                  </div>
                  {openOrder === user?.UserID && (
                    <div className="mobile-admin-user-bottom-products">
                      <div className="main-carts-products-div-container">
                        {user?.Preorders &&
                          user?.Preorders?.length > 0 &&
                          user?.Preorders?.map((data, index) => (
                            <div
                              className="w-full flex flex-col items-start justify-start bg-white-1 mx-0 px-3 border-b-[1px] mb-1 border-gray-300 relative"
                              key={index}
                            >
                              <div className="w-full h-[135px] flex items-start justify-start">
                                <div className="w-[38%] h-full flex-center relative">
                                  <img loading="lazy"
                                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                      data?.image && data?.image
                                    }`}
                                    alt="product-img"
                                    className="w-full h-full object-contain"
                                  />
                                </div>
                                <div className="w-[62%] h-full flex flex-col items-start justify-between ml-4 relative pt-2">
                                  <div className="w-[50%] h-[22px]">
                                    {data && data?.manufacture_images ? (
                                      <img loading="lazy"
                                        className="max-w-[120px] max-h-5 h-full object-contain"
                                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                                          data && data?.manufacture_images
                                        }`}
                                        alt="brand-logo"
                                      />
                                    ) : (
                                      <h3 className="text-16 italic font-bold">
                                        {data?.manufacture_name &&
                                          data?.manufacture_name}
                                      </h3>
                                    )}
                                  </div>
                                  <span className="w-full h-[55px] text-start font-sans leading-[1] mt-1 text-[18px] text-black-2 font-bold capitalize">
                                    {data?.prod_name &&
                                      (data?.prod_name)
                                        .slice(0, 45)
                                        .toLowerCase()
                                        .charAt(0)
                                        .toUpperCase() +
                                        (data?.prod_name)
                                          .slice(0, 45)
                                          .toLowerCase()
                                          .slice(1)}
                                    {data?.prod_name?.length > 45 && "..."}
                                  </span>
                                  <div className="flex items-center justify-between font-sans leading-[1] bg-gray-100 px-2 py-[2px] mt-1 rounded-sm font-bold shadow-sm shadow-black-1/20">
                                    <div className="text-[14px] font-medium text-black-2 flex items-center">
                                      <span>4.7</span>
                                      <StarRoundedIcon
                                        className="ml-1 text-[#daa520]"
                                        style={{ fontSize: "16px" }}
                                      />
                                    </div>
                                    <span className="text-[14px] text-black-1 mx-[2px] font-medium">
                                      |
                                    </span>
                                    <span className="text-[14px] ml-2 font-medium text-black-2">
                                      4K
                                    </span>
                                  </div>
                                  <div className="w-full flex flex-col">
                                    <span className="w-full h-[28px]  text-[16px] leading-[1] font-sans font-semibold text-blue flex items-center whitespace-nowrap">
                                      Dealer Price- ₹
                                      <b
                                        style={{
                                          textDecoration:
                                            data?.Offer_price !== null &&
                                            "line-through",
                                          fontSize:
                                            data?.Offer_price !== null &&
                                            "14px",
                                        }}
                                      >
                                        {formatWithCommasWithDecimal(
                                          calculateDealerPrice(
                                            parseInt(data?.MRP_price),
                                            parseInt(data?.NDP_price),
                                            data?.GST
                                          )
                                        )}
                                      </b>
                                      {userDetail &&
                                        Object.keys(userDetail)?.length > 0 &&
                                        data?.Offer_price !== null && (
                                          <span className="text-[18px] text-green ml-1 font-semibold">
                                            ₹
                                            {formatWithCommasWithDecimal(
                                              calculateOfferPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.Offer_price),
                                                data?.GST
                                              )
                                            )}
                                          </span>
                                        )}
                                    </span>
                                    <span className="w-full flex items-start text-[15px] leading-[1] font-sans font-semibold text-blue">
                                      Product MRP- ₹
                                      {formatWithCommasWithDecimal(
                                        parseInt(data?.MRP_price)
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full flex items-center justify-between mt-3 mb-1">
                                <span className="text-[16px] font-sans leading-[1] text-black-1/80 w-[35px] h-[20px] text-center tracking-wide mx-2">
                                  Qty:{data?.quantity}
                                </span>
                                <div className="flex items-center">
                                  <div className="flex flex-col mr-1">
                                    <span className="text-[18px] font-sans leading-[1] text-black-1/80">
                                      ₹{" "}
                                      {formatWithCommasWithDecimal(
                                        (data?.Offer_price
                                          ? calculateOfferPrice(
                                              parseInt(data?.MRP_price),
                                              parseInt(data?.Offer_price),
                                              data?.GST
                                            )
                                          : calculateDealerPrice(
                                              parseInt(data?.MRP_price),
                                              parseInt(data?.NDP_price),
                                              data?.GST
                                            )) * data?.quantity
                                      )}
                                    </span>
                                    <span className="text-[16px] font-medium text-black-1/80 font-sans leading-[1]">
                                      Price Details
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <NoCartItem text={"Pre-Book"} />
      )}
    </div>
  );
}

export default MobileAdminPreOrder;
