import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllBanners,
  fetchAllInclusiveBrands,
  fetchAllOurBrands,
  fetchAllNavigations,
  fetchAllWhatsNew,
  fetchAllRecommended,
  fetchAllHotDeals,
  fetchAllWishlst,
  fetchAllBannersMobile,
} from "./homeAPI";

const initialState = {
  hotlists: [],
  banners: [],
  mobileBanners: [],
  promises: [],
  spotlights: [],
  ourBrands: [],
  inclusiveBrands: [],
  navigations: [],
  promotions: [],
  status: "idle",
};

export const fetchAllWhatsNewAsync = createAsyncThunk(
  "promotion/fetchAllWhatsNew",
  async () => {
    const response = await fetchAllWhatsNew();
    return response.data;
  }
);
export const fetchAllRecommendedAsync = createAsyncThunk(
  "promotion/fetchAllRecommended",
  async () => {
    const response = await fetchAllRecommended();
    return response.data;
  }
);
export const fetchAllHotDealsAsync = createAsyncThunk(
  "promotion/fetchAllHotDeals",
  async () => {
    const response = await fetchAllHotDeals();

    return response.data;
  }
);
export const fetchAllWishlstAsync = createAsyncThunk(
  "promotion/fetchAllWishlst",
  async (UserID) => {
    const response = await fetchAllWishlst(UserID);

    return response.data;
  }
);

export const fetchAllNavigationsAsync = createAsyncThunk(
  "navigation/fetchAllNavigations",
  async (vertical) => {
    const response = await fetchAllNavigations(vertical);
    return response.data;
  }
);

export const fetchAllPromisesAsync = createAsyncThunk(
  "promise/fetchAllPromises",
  async () => {
    const response = await fetchAllBanners();
    return response.data;
  }
);
export const fetchAllBannersAsync = createAsyncThunk(
  "banner/fetchAllBanners",
  async () => {
    const response = await fetchAllBanners();
    return response?.data?.data[0]?.images;
  }
);
export const fetchAllBannersMobileAsync = createAsyncThunk(
  "banner/fetchAllBannersMobile",
  async () => {
    const response = await fetchAllBannersMobile();
    return response?.data?.data[0]?.images;
  }
);
export const fetchAllOurBrandsAsync = createAsyncThunk(
  "ourBrand/fetchAllOurBrands",
  async () => {
    const response = await fetchAllOurBrands();
    return response.data;
  }
);
export const fetchAllInclusiveBrandsAsync = createAsyncThunk(
  "inclusiveBrand/fetchAllInclusiveBrands",
  async () => {
    const response = await fetchAllInclusiveBrands();
    return response.data;
  }
);

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNavigationsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllNavigationsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.navigations = action.payload;
      });
  },
});

export const PromotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWhatsNewAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllWhatsNewAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.promotions = action.payload;
      })
      .addCase(fetchAllRecommendedAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRecommendedAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.promotions = action.payload;
      })
      .addCase(fetchAllHotDealsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllHotDealsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.promotions = action.payload;
      })
      .addCase(fetchAllWishlstAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllWishlstAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.promotions = action.payload;
      });
  },
});

export const promiseSlice = createSlice({
  name: "promise",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPromisesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPromisesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.promises = action.payload;
      });
  },
});
export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBannersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBannersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.banners = action.payload;
      })
      .addCase(fetchAllBannersMobileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBannersMobileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mobileBanners = action.payload;
      });
  },
});

export const ourBrandSlice = createSlice({
  name: "ourBrand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOurBrandsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOurBrandsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.ourBrands = action.payload;
      });
  },
});
export const inclusiveBrandSlice = createSlice({
  name: "inclusiveBrand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInclusiveBrandsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllInclusiveBrandsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.inclusiveBrands = action.payload;
      });
  },
});

export const selectAllNavigations = (state) => state.navigation.navigations;
export const selectAllHostlists = (state) => state.hotlist.hotlists;
export const selectAllBanners = (state) => state.banner.banners;
export const selectAllMobileBanners = (state) => state.banner.mobileBanners;
export const selectAllPromises = (state) => state.promise.promises;
export const selectAllSpotlights = (state) => state.spotlight.spotlights;
export const selectAllOurBrands = (state) => state.ourBrand.ourBrands;
export const selectAllPromotion = (state) => state.promotion.promotions;
export const selectAllInclusiveBrands = (state) =>
  state.inclusiveBrand.inclusiveBrands;

export default PromotionSlice.reducer;
