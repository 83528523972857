import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PartsIcon from "../../img/navigation/parts.png";
import accessoriesIcon from "../../img/navigation/accessories.png";
import clothingIcon from "../../img/navigation/clothing.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllNavigationsAsync,
  selectAllNavigations,
} from "../../features/HomePage/homeSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { useNavigate } from "react-router";
import { IMAGE_BASE } from "../../config";
import { Slide } from "react-reveal";

function MobileBigNavigation({ setShowNavigation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verticalDetail = useSelector(selectAllNavigations);
  const [nav, setNav] = useState(1);

  useEffect(() => {
    dispatch(fetchAllNavigationsAsync(1));
  }, [dispatch]);

  const handleNavigations = (vertical) => {
    if (nav === vertical) {
      setNav(null);
    } else {
      dispatch(fetchAllNavigationsAsync(vertical));
      setNav(vertical);
    }
  };
  const handleBrandNavigate = (value) => {
    const lowercaseValue = value.toLowerCase();
    navigate(`/listing-page/${lowercaseValue}`);
    setShowNavigation(false);
  };

  const handelCategoryNavigate = (catID, catName) => {
    navigate(`/listing-page/${catID}/${catName}`);
    dispatch(setSelectedSubcategory(null));
    // dispatch(fetchAllCategoryProductsAsync(catID));
    setShowNavigation(false);
  };
  return (
    <Slide right>
      <div className="w-screen h-screen fixed left-0 top-[58px] bottom-0 right-0 bg-white-1 flex flex-col items-start justify-start z-999">
        <div className="w-full relative flex items-center justify-between py-2 px-4">
          <ChevronLeftIcon
            style={{fontSize:"28px"}} className="text-black-1/80"
            onClick={() => setShowNavigation(false)}
          />
          <span className="absolute left-1/2 transform -translate-x-[50%] text-16 text-black-1/80 font-bold">All Categories</span>
        </div>
        <div className="w-full flex items-center justify-between py-3 px-4 border-t-[1px] border-black-1/15">
          <div
            className={`w-[30%] flex flex-col items-center justify-start px-4 pb-1 relative ${
              nav === 1 && "before:w-full before:absolute before:left-0 before:bottom-0 before:border-[1px] before:border-green"
            }`}
            onClick={() => handleNavigations(1)}
          >
            <img loading="lazy"
              src={PartsIcon}
              alt="part-icon"
              className="h-[25px]"
            />
            <span className="text-16 text-black-1/80 my-1">Parts</span>
          </div>
          <div
            className={`w-[30%] flex flex-col items-center justify-start px-4 pb-1 relative ${
              nav === 2 && "before:w-full before:absolute before:left-0 before:bottom-0 before:border-[1px] before:border-green"
            }`}
            onClick={() => handleNavigations(2)}
          >
            <img loading="lazy"
              src={accessoriesIcon}
              alt="accessories-icon"
              className="h-[25px]"
            />
            <span className="text-16 text-black-1/80 my-1">
              Accessories
            </span>
          </div>
          <div
            className={`w-[30%] flex flex-col items-center justify-start px-4 pb-1 relative ${
              nav === 3 && "before:w-full before:absolute before:left-0 before:bottom-0 before:border-[1px] before:border-green"
            }`}
            onClick={() => handleNavigations(3)}
          >
            <img loading="lazy"
              src={clothingIcon}
              alt="cloth-icon"
              className="h-[25px]"
            />
            <span className="text-16 text-black-1/80 my-1">
              Maintenance
            </span>
          </div>
        </div>
        <div className="w-full flex items-start justify-start flex-wrap py-2 px-3">
          {verticalDetail?.categories &&
            verticalDetail?.categories.map((category, index) => (
              <div
                className="w-[25%] flex flex-col items-center justify-start pb-2"
                key={index}
                onClick={() =>
                  handelCategoryNavigate(
                    category?.CategoryID,
                    category?.categoryName
                  )
                }
              >
                <img loading="lazy"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${
                    category?.image && category?.image
                  }`}
                  alt="cat-logo"
                  className="w-[55px] h-[55px] rounded-lg"
                />
                <span className="w-full h-[30px] text-[14px] font-bold text-black-1/80 my-1 text-center">
                  {category.categoryName && category?.categoryName}
                </span>
              </div>
            ))}
        </div>
        <div className="w-full flex flex-col items-start justify-start py-2 px-3">
          <span className="text-18 text-black-1/80 font-bold ml-1">Top Brands</span>
          <div className="w-full flex items-start justify-start flex-wrap p-0">
            {verticalDetail?.manufacturers &&
              verticalDetail?.manufacturers.map((brand, index) => (
                <div className="w-[20%] m-2" key={index}>
                  <img loading="lazy"
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                      brand?.manufacture_images && brand?.manufacture_images
                    }`}
                    alt=""
                    className="w-full h-[30px] object-contain"
                    onClick={() =>
                      handleBrandNavigate(
                        brand?.manufacture_name && brand?.manufacture_name
                      )
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default MobileBigNavigation;
