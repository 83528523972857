import React, { useState, useEffect, useRef } from "react";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAddressAsync,
  selectAllAddress,
  setSelectedAddress,
  selectSelectedAddress,
} from "../../features/cartPage/addressSlice";
import { selectUser } from "../../features/auth/authSlice";

import AddNewAddress from "./SaveAddress/AddNewAddress";
import aadharLogo from "../../img/banners/aadhar-logo.png";
import panLogo from "../../img/banners/pan-card-logo.png";
import gstLogo from "../../img/banners/gst-logo.png";
import EditAddressDetail from "./SaveAddress/EditAddressDetail";

import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { useNavigate } from "react-router";
import ProfileSideBar from "../../mComponent/ProfileSideBar";
import BasicProfile from "../../dComponent/profile/BasicProfile";
import AddressCard from "../../dComponent/Address/AddressCard";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import NewAddressForm from "../../dComponent/Address/NewAddressForm";

function MyProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addresses = useSelector(selectAllAddress);
  const selectedAddress = useSelector(selectSelectedAddress);
  const userDetail = useSelector(selectUser);
  const [openClick, setOpenClick] = useState(false);
  const [openOverlay, setOpenOverlay] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const openClickRef = useRef(null);
  const [addAddress, setAddAddress] = useState(false);

  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };


  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const defaultAddress = addresses.find((addr) => addr.is_default === 1);
      dispatch(
        setSelectedAddress(defaultAddress ? defaultAddress.shippingID : null)
      );
    }
  }, [addresses, dispatch]);

  const handleOutsideClick = (event) => {
    if (openClickRef.current && !openClickRef.current.contains(event.target)) {
      setOpenClick(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const formatAadharNumber = (aadharNumber) => {
    // Check if aadharNumber is provided and has 12 characters
    if (aadharNumber && aadharNumber.length === 12) {
      // Replace first 8 characters with 'X' and split the Aadhar number into groups of 4 characters
      const formattedAadharNumber = "XXXX-XXXX-" + aadharNumber.slice(-4);
      return formattedAadharNumber;
    } else {
      return aadharNumber; // Return original number if not valid
    }
  };
  const formatPanCardNumber = (panCardNumber) => {
    if (!panCardNumber) return panCardNumber;

    // Mask all characters except the last four with 'X'
    const maskedPanCardNumber = panCardNumber.slice(0, -4).replace(/./g, "X");

    // Return the masked PAN card number along with the last four digits, separated by space
    return (
      maskedPanCardNumber.slice(0, 5) +
      " " +
      maskedPanCardNumber.slice(5) +
      panCardNumber.slice(-4, -1) +
      " " +
      panCardNumber.slice(-1)
    );
  };
  const formatGSTNumber = (gstNumber) => {
    if (!gstNumber) return gstNumber;

    // Mask all characters except the last four with 'X'
    const maskedGSTNumber = gstNumber.slice(0, -4).replace(/./g, "X");

    // Return the masked GST number along with the last four digits, separated by space
    return (
      maskedGSTNumber.slice(0, 2) +
      " " +
      maskedGSTNumber.slice(2, 7) +
      " " +
      maskedGSTNumber.slice(7, 11) +
      " " +
      gstNumber.slice(-4, -2) +
      " " +
      gstNumber.slice(-2)
    );
  };

  return (
    <div className="w-full h-auto flex flex-col py-0 px-0 pl-0 sm:py-2 sm:px-4 sm:pl-1 flex-1 max-sm:px-3 relative">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <p className="text-18 text-black-1 font-bold tracking-wider pt-1 flex max-sm:hidden">
        My Profile
      </p>
      <div className="w-screen fixed top-[58px] left-0 right-0 z-999 bg-white-1 shadow-md shadow-black-1/20 py-3 px-3 flex items-center justify-between sm:hidden">
        <div className="w-auto flex items-center">
          <ChevronLeftOutlinedIcon
            onClick={() => navigate("/")}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
          <span className="text-16 text-black-1/80 font-bold flex items-center">
            My Profile
          </span>
        </div>
        <div className="h-full">
          <MoreHorizOutlinedIcon
            onClick={handleSidebar}
            className="text-black-1/80"
            style={{ fontSize: "20px" }}
          />
        </div>
        {openSidebar && (
          <ProfileSideBar
            handleSidebar={handleSidebar}
            openSidebar={openSidebar}
          />
        )}
      </div>
      <BasicProfile userDetail={userDetail} />
      <div className="w-full flex flex-col bg-white-1 p-4 my-4 rounded-md shadow-md shadow-black-1/10">
        <h3 className="text-[16px] text-black-1 font-bold tracking-wider">
          Address
        </h3>
        <div className="flex items-center flex-wrap text-black-1/40 mt-2">
          {addresses &&
            addresses.length > 0 &&
            addresses?.map((address) => (
              <AddressCard
                address={address}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                handleOpen={handleOpen}
                handleClose={handleClose}
                userDetail={userDetail}
              />
            ))}
          <div
            className="w-full sm:w-[400px] h-[180px] bg-black-6/10 flex-center flex-col border-2 border-dashed border-black-1/10 rounded-md mt-3 sm:mt-2 relative text-black-1 cursor-pointer"
            onClick={() => setAddAddress(true)}
          >
            <AddIcon className="bg-white-1 w-[40px] h-[40px] p-1 rounded-sm" />
            <span className="text-[15px] text-black-1/80 font-semibold mt-2">
              Add new address
            </span>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col bg-white-1 p-4 my-4 rounded-md shadow-md shadow-black-1/10 mb-[80px] sm:mb-0">
        <h3 className="text-[16px] text-black-1 font-bold tracking-wider">
          Documents
        </h3>
        <div className="w-full flex items-center flex-wrap text-black-1/40 mt-2">
          <div className="w-full sm:w-[250px] h-[160px] border-2 border-black-1/30 rounded-md flex-center flex-col mr-0 my-2 sm:mr-4">
            <img loading="lazy" src={aadharLogo} alt="adhar-logo" className="h-[20px] my-1" />
            <span className="text-[14px] text-black-1 font-bold tracking-wide my-1">
              Aadhar Card
            </span>
            <span className="text-[16px] text-black-1 font-semibold tracking-wide my-1">
              {userDetail && formatAadharNumber(userDetail?.aadhar)}
            </span>
          </div>
          <div className="w-full sm:w-[250px] h-[160px] border-2 border-black-1/30 rounded-md flex-center flex-col mr-0 my-2 sm:mr-4">
            <img loading="lazy" src={panLogo} alt="pan-logo" className="h-[20px] my-1" />
            <span className="text-[14px] text-black-1 font-bold tracking-wide my-1">
              Pan Card
            </span>
            <span className="text-[16px] text-black-1 font-semibold tracking-wide my-1">
              {userDetail && formatPanCardNumber(userDetail?.pan_card)}
            </span>
          </div>
          <div className="w-full sm:w-[250px] h-[160px] border-2 border-black-1/30 rounded-md flex-center flex-col mr-0 my-2 sm:mr-4">
            <img loading="lazy" src={gstLogo} alt="gst-logo" className="h-[20px] my-1" />
            <span className="text-[14px] text-black-1 font-bold tracking-wide my-1">
              GST
            </span>
            <span className="text-[16px] text-black-1 font-semibold tracking-wide my-1">
              {userDetail && formatGSTNumber(userDetail?.GST)}
            </span>
          </div>
        </div>
      </div>

      {addAddress && (
        <NewAddressForm addAddress={addAddress} setAddAddress={setAddAddress} />
      )}
      {/* {editAddressDetail && (
        <EditAddressDetail
          handleEditAddressDetail={handleEditAddressDetail}
          sendAddress={sendAddress}
        />
      )} */}
    </div>
  );
}

export default MyProfile;
