import React, { useEffect, useState } from "react";
import { Slide } from "react-reveal";
import kingLogo from "../../icons/badges/king.png";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import LogoutOverlay from "../../components/EndPages/LogoutOverlay";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { ccHundred } from "../../features/cartPage/walletAPI";
import {
  fetchCreditPageDetailsAsync,
  selectAllCreditData,
} from "../../features/credit/creditSlice";
import logoutLogo from "../../assets/Icon-logout.svg";
import orderLogo from "../../assets/Icon-orders.svg";
import userLogo from "../../assets/Icon-user.svg";
import walletLogo from "../../assets/Icon-wallet.svg";
import lineOfCreditLogo from "../../assets/Icon-LOC1.svg";
import preorderLogo from "../../assets/Icon-preorder.svg";
import wishlistLogo from "../../assets/wishlistLogo.svg";
import whatsappLogo from "../../assets/whatsappLogo.svg";
import privacyLogo from "../../assets/privacyLogo.svg";

function MobileSideBar({
  setOpenBurger,
  openBurger,
  isOverlayVisible,
  toggleOverlay,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const creditData = useSelector(selectAllCreditData);
  const [openLogout, setOpenLogout] = useState(false);
  const [premUser, setPremUser] = useState(false);
  const handleNavigate = (url) => {
    navigate(`/user/${url}`);
  };
  const handleOpenLogout = () => {
    setOpenLogout(!openLogout);
  };
  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);
  useEffect(() => {
    const responsePromise = ccHundred(userDetail?.UserID);
    responsePromise
      .then((response) => {
        if (response && response?.data && response?.data?.message) {
          setPremUser(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error if necessary
      });
  }, [userDetail?.UserID]);

  return (
    <Slide right>
      <div className="w-screen h-screen fixed top-[58px] left-0 bg-white-1 flex flex-col items-start justify-start z-999">
        <div className="w-full flex items-center justify-start py-3 px-4 relative">
          <div className="absolute top-2 right-3 text-black-2 text-16">
            <CloseIcon
              onClick={() => setOpenBurger(false)}
              style={{ fontSize: "20px" }}
            />
          </div>
          <img loading="lazy"
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
              userDetail?.UserImage || ""
            }`}
            alt="user-logo"
            className="w-[80px] h-[80px] rounded-full mr-3 border-gray-200 border-[1px]"
          />
          <div className="w-auto flex flex-col items-start justify-start">
            <span className="text-[16px] text-black-3 font-bold">
              {userDetail && userDetail?.FirstName}{" "}
              {userDetail && userDetail?.LastName}
            </span>
            <span className="text-[14px] my-[2px] text-black-6/70 font-semibold">
              {userDetail && userDetail?.Email}
            </span>
            <span className="text-[14px] my-[2px] text-black-6/70 font-semibold">
              {userDetail && userDetail?.PhoneNumber}
            </span>
            {openLogout && (
              <LogoutOverlay
                openLogout={openLogout}
                handleOpenLogout={handleOpenLogout}
              />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start py-2 px-4">
          <span className="text-[18px] text-black-1/80 font-bold tracking-wider mb-2">
            My Account
          </span>
          {premUser && (
            <div className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10">
              <div
                className="flex items-center justify-start"
                onClick={() => {
                  Object.keys(userDetail).length
                    ? navigate("/admin/dashboard")
                    : toggleOverlay();
                  setOpenBurger(false);
                }}
              >
                <img loading="lazy" src={kingLogo} alt="king-logo" className="w-[24px] mr-3" />
                <span className="text-16 text-[goldenrod] font-semibold tracking-wider">
                  Admin Dashboard
                </span>
              </div>
            </div>
          )}

          <div className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10">
            <div
              className="flex items-center justify-start"
              onClick={() => {
                Object.keys(userDetail).length
                  ? navigate("/user/profile")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <img loading="lazy"
                src={userLogo}
                alt="LOC-logo"
                width={25}
                height={25}
                className="mr-3"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider">
                My Profile
              </span>
            </div>
          </div>
          <div className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10">
            <div
              className="flex items-center justify-start"
              onClick={() => {
                Object.keys(userDetail).length
                  ? handleNavigate("preorders")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <img loading="lazy"
                src={preorderLogo}
                alt="LOC-logo"
                width={25}
                height={25}
                className="mr-3"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider ">
                My Pre-Orders
              </span>
            </div>
          </div>
          <div className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10">
            <div
              className="flex items-center justify-start"
              onClick={() => {
                Object.keys(userDetail).length
                  ? handleNavigate("orders")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <img loading="lazy"
                src={orderLogo}
                alt="LOC-logo"
                width={25}
                height={25}
                className="mr-3"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider ">
                My Orders
              </span>
            </div>
          </div>
          {creditData?.creditDetails && (
            <div
              className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10"
              onClick={() => {
                Object.keys(userDetail).length
                  ? navigate("/user/credit")
                  : toggleOverlay();
                setOpenBurger(false);
              }}
            >
              <div className="flex items-center justify-start">
                <img loading="lazy"
                  src={lineOfCreditLogo}
                  alt="LOC-logo"
                  width={28}
                  height={28}
                  className="mr-3 -ml-[2px]"
                />
                <span className="text-16 text-black-1/80 font-semibold tracking-wider">
                  Line of Credit
                </span>
              </div>
            </div>
          )}
          <div
            className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10"
            onClick={() => {
              Object.keys(userDetail).length
                ? navigate("/wishlist")
                : toggleOverlay();
              setOpenBurger(false);
            }}
          >
            <div className="flex items-center justify-start">
              <img loading="lazy"
                src={wishlistLogo}
                alt="LOC-logo"
                width={28}
                height={28}
                className="mr-3 -ml-[2px]"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider">
                Wishlist
              </span>
            </div>
          </div>
          <div
            className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10"
            onClick={() => {
              Object.keys(userDetail).length
                ? navigate("/user/wallet")
                : toggleOverlay();
              setOpenBurger(false);
            }}
          >
            <div className="flex items-center justify-start">
              <img loading="lazy"
                src={walletLogo}
                alt="LOC-logo"
                width={25}
                height={25}
                className="mr-3"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider">
                Wallet
              </span>
            </div>
          </div>
          <hr className="border-gray-100 border-[1px] w-full mb-2" />
          <span className="text-20 text-black-2/80 font-bold tracking-wider">
            Others
          </span>
          <Link
            to="https://wa.me/9429693000"
            target="_blank"
            className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10"
          >
            <div className="flex items-center justify-start">
              <img loading="lazy"
                src={whatsappLogo}
                alt="LOC-logo"
                width={25}
                height={25}
                className="mr-3"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider ">
                Chat Us
              </span>
            </div>
          </Link>
          <div
            className="w-full flex items-center justify-between m-0 rounded-md p-[10px] hover:bg-black-1/10"
            onClick={() => navigate("/privacypolicy")}
          >
            <div className="flex items-center justify-start">
              <img loading="lazy"
                src={privacyLogo}
                alt="LOC-logo"
                width={30}
                height={30}
                className="mr-3 -ml-1"
              />
              <span className="text-16 text-black-1/80 font-semibold tracking-wider">
                Privacy Policy
              </span>
            </div>
          </div>
          {Object.keys(userDetail).length && (
            <div
              className="w-full flex items-center justify-between m-0 rounded-md p-[10px]"
              onClick={handleOpenLogout}
            >
              <div className="flex items-center justify-start">
                <img loading="lazy"
                  src={logoutLogo}
                  alt="LOC-logo"
                  width={25}
                  height={25}
                  className="mr-[14px]"
                />
                <span className="text-16 text-peach font-semibold tracking-wider">
                  Log Out
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Slide>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileSideBar);
