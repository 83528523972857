import React from "react";
import {
  calculateAmountToPay,
  calculateShippingCost,
  calculateTotalAmount,
  formatWithCommasWithDecimal,
  steps,
} from "../../utils/utils";
import { Button, useMediaQuery } from "@mui/material";

import EastIcon from "@mui/icons-material/East";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";

function PriceDetail({
  cart,
  currentStep,
  setCurrentStep,
  useWalletAmount,
  selectedCoupon,
  useCreditAmount,
  setUseWalletAmount,
  setUseCreditAmount,
  selectedAddress,
  setGeneratePaymentUrl,
  setOpenPaymentModel,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const nextStepIndex =
    currentStep < steps.length ? currentStep + 1 : currentStep;
  const nextLabel = steps[nextStepIndex - 1]?.label;

  return (
    <div className="w-full flex flex-col max-sm:bg-white-1">
      <h3 className="text-[16px] text-black-1 font-bold tracking-wide p-4 border-b-[1px] border-black-1/10">
        Payment Summary
      </h3>
      <div className="w-full flex-col items-center p-4">
        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Total Price
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1.5px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            ₹{formatWithCommasWithDecimal(parseInt(calculateTotalAmount(cart)))}
          </span>
        </div>
        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Shipping Cost
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1.5px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            -₹{formatWithCommasWithDecimal(calculateShippingCost(cart))}
          </span>
        </div>
        {parseInt(selectedCoupon?.couponDiscountTaken) > 0 && (
          <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
            <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
              Coupon Discount
            </p>
            <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1.5px] border-black-1 " />
            <span className="text-[14px] text-black-1 font-semibold tracking-wide">
              -₹
              {formatWithCommasWithDecimal(
                parseInt(selectedCoupon?.couponDiscountTaken)
              )}
            </span>
          </div>
        )}
        {parseInt(useWalletAmount) > 0 && (
          <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
            <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
              Wallet
            </p>
            <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1.5px] border-black-1 " />
            <span className="text-[14px] text-black-1 font-semibold tracking-wide">
              -₹{formatWithCommasWithDecimal(parseInt(useWalletAmount))}
            </span>
          </div>
        )}
        {parseInt(useCreditAmount) > 0 && (
          <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
            <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
              Line of Credit
            </p>
            <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1.5px] border-black-1 " />
            <span className="text-[14px] text-black-1 font-semibold tracking-wide">
              -₹{formatWithCommasWithDecimal(parseInt(useCreditAmount))}
            </span>
          </div>
        )}

        <div className="w-full flex items-center justify-between relative pt-4 border-t-[1px] border-black-1/10 max-sm:pb-[25px]">
          <p className="text-[16px] text-peach font-semibold tracking-wide">
            Amount to pay
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1.5px] border-peach " />
          <span className="text-[16px] text-peach font-semibold tracking-wide">
            ₹
            {formatWithCommasWithDecimal(
              parseInt(
                calculateAmountToPay(
                  cart,
                  selectedCoupon?.couponDiscountTaken,
                  useWalletAmount,
                  useCreditAmount,
                  setUseWalletAmount,
                  setUseCreditAmount
                )
              )
            )}
          </span>
        </div>

        {isMobile ? (
          <div
            className="w-screen h-[70px] flex items-center justify-between fixed left-0 bottom-0 right-0 bg-white-1 z-10 px-3"
            style={{ boxShadow: "0 -3px 5px 0 rgba(0,0,0,0.1)" }}
          >
            <div className="w-[28%] h-full flex flex-col justify-center">
              <span className="text-black-1/80 font-semibold text-12">
                Total Amount-
              </span>
              <span className="text-18 text-black-1 font-bold">
                ₹
                {formatWithCommasWithDecimal(
                  parseInt(
                    calculateAmountToPay(
                      cart,
                      selectedCoupon?.couponDiscountTaken,
                      useWalletAmount,
                      useCreditAmount,
                      setUseWalletAmount,
                      setUseCreditAmount
                    )
                  )
                )}
              </span>
            </div>
            <Button
              variant="contained"
              sx={{
                width: "60%",
                height: "40px",
                background: "#28a447",
                outline: "none",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "700",
                fontSize: "15px",
                whiteSpace: "nowrap",
                "& svg": {
                  fontSize: "20px",
                  marginLeft: "8px",
                },
                "&:hover": {
                  background: "#28a447",
                },
              }}
              onClick={() => {
                setCurrentStep((prev) => {
                  let nextStep = prev;
                  if (prev < 2) {
                    if (cart && cart?.length > 0) {
                      nextStep = prev + 1;
                    } else {
                      toast.error("Please add items in cart");
                    }
                  } else if (prev === 2 && selectedAddress !== null) {
                    nextStep = 3;
                  } else if (prev === 2 && selectedAddress === null) {
                    // Display SweetAlert if selectedAddress is null
                    toast.error("Please add an address");
                  } else if (prev === 3) {
                    setGeneratePaymentUrl(true);
                    setOpenPaymentModel(true);
                  }
                  return nextStep;
                });
              }}
            >
              Proceed to {nextLabel}
              <EastIcon
                className="text-white-1 ml-3 sm:ml-2"
                style={{ fontSize: "16px" }}
              />
            </Button>
          </div>
        ) : (
          <div className="w-full flex items-center justify-between relative pt-4">
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: isMobile ? "40px" : "35px",
                borderRadius: isMobile ? "4px" : "30px",
                padding: "5px 8px",
                background: "#283248",
                fontSize: isMobile ? "16px" : "14px",
                fontWeight: "500",
                color: "white",
                textTransform: "none",
                letterSpacing: "0.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: "#283248",
                },
              }}
              className="breadcrum-next-step"
              onClick={() => {
                setCurrentStep((prev) => {
                  let nextStep = prev;
                  if (prev < 2) {
                    if (cart && cart?.length > 0) {
                      nextStep = prev + 1;
                    } else {
                      toast.error("Please add items in cart");
                    }
                  } else if (prev === 2 && selectedAddress !== null) {
                    nextStep = 3;
                  } else if (prev === 2 && selectedAddress === null) {
                    // Display SweetAlert if selectedAddress is null
                    toast.error("Please add an address");
                  } else if (prev === 3) {
                    setGeneratePaymentUrl(true);
                    setOpenPaymentModel(true);
                  }
                  return nextStep;
                });
              }}
            >
              Proceed to {nextLabel}
              <EastIcon
                className="text-white-1 ml-3 sm:ml-2"
                style={{ fontSize: "16px" }}
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PriceDetail;
