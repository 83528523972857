import React, { useEffect, useState } from "react";
// import "./MobilePromotion.css";
import {
  fetchAllHotDealsAsync,
  fetchAllRecommendedAsync,
  fetchAllWhatsNewAsync,
} from "../../../features/HomePage/homeSlice";
import { fetchAllCategoryProductsAsync } from "../../../features/listingPage/listingPageSlice";
import { useDispatch } from "react-redux";
import MobileProduct from "./MobileProduct";

function MobilePromotion() {
  const dispatch = useDispatch();
  const [promotionContent, setPromotionContent] = useState(1);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    dispatch(fetchAllWhatsNewAsync());
  }, [dispatch]);

  useEffect(() => {
    // Function to change promotion tab automatically after 10 seconds
    const id = setInterval(() => {
      setPromotionContent((prevContent) => {
        const nextContent =
          prevContent === 4 ? 1 : prevContent + 1 === 3 ? 4 : prevContent + 1;
        dispatch(fetchAllCategoryProductsAsync(nextContent));
        return nextContent;
      });
    }, 10000); // Change tab every 10 seconds

    setIntervalId(id);

    return () => clearInterval(id); // Cleanup interval on component unmount
  }, [dispatch]);

  const handlePromotionContent = (value) => {
    clearInterval(intervalId);
    if (value === 1) {
      dispatch(fetchAllWhatsNewAsync());
    } else if (value === 2) {
      dispatch(fetchAllRecommendedAsync());
    } else {
      dispatch(fetchAllHotDealsAsync());
    }

    setPromotionContent(value);
  };

  const handleMouseEnter = () => {
    clearInterval(intervalId); // Pause the interval
  };

  const handleMouseLeave = () => {
    clearInterval(intervalId);
    const id = setInterval(() => {
      setPromotionContent((prevContent) => {
        const nextContent =
          prevContent === 4 ? 1 : prevContent + 1 === 3 ? 4 : prevContent + 1;
        if (nextContent === 1) {
          dispatch(fetchAllWhatsNewAsync());
        }
        if (nextContent === 2) {
          dispatch(fetchAllRecommendedAsync());
        }
        if (nextContent === 4) {
          dispatch(fetchAllHotDealsAsync());
        }
        return nextContent;
      });
    }, 10000); // Change tab every 10 seconds

    setIntervalId(id);
  };
  return (
    <div className="w-screen flex flex-col items-center relative bg-white-1">
      <div className="w-full flex items-center justify-between bg-white pt-2 pb-4 px-0 shadow-lg">
        <div
          className={`what w-[30%] flex-center py-2 relative  ${
            promotionContent === 1 && "mobile-what-navigation-active"
          }`}
        >
          <span
            className={`text-16 text-blue font-extrabold tracking-wide cursor-pointer relative ${
              promotionContent === 1 ? "text-blue" : "text-blue"
            }`}
            onClick={() => handlePromotionContent(1)}
          >
            What's New
          </span>
        </div>
        <div
          className={`recommend w-[30%] flex-center py-2 relative ${
            promotionContent === 2 && "mobile-recommend-navigation-active"
          }`}
        >
          <span
            className={`text-16  font-extrabold tracking-wide cursor-pointer relative ${
              promotionContent === 2 ? "text-peach" : "text-blue"
            }`}
            onClick={() => handlePromotionContent(2)}
          >
            Recommended
          </span>
        </div>
        <div
          className={`w-[30%]  flex-center py-2 relative ${
            promotionContent === 4 && "mobile-hot-navigation-active"
          }`}
        >
          <span
            className={`text-16  font-extrabold tracking-wide cursor-pointer relative  ${
              promotionContent === 4 ? "text-green" : "text-blue"
            }`}
            onClick={() => handlePromotionContent(4)}
          >
            Hot Deals
          </span>
        </div>
      </div>
      <div className="w-full">
        <MobileProduct
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </div>
      <div className="w-full py-[2.5vh] bg-white-1"></div>
    </div>
  );
}

export default MobilePromotion;
