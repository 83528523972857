import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setSortPrice, setSortRating } from "../store/slices/allFilterSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import upIcon from "../icons/sortby/sort-up.png";
import downIcon from "../icons/sortby/sort-below.png";
import upIconWhite from "../icons/sortby/sort-up-white.png";
import downIconWhite from "../icons/sortby/sort-down-white.png";
import { SwipeableDrawer } from "@mui/material";

const SortBy = ({
  onSelect,
  setOpenFilter,
  previouslySortPrice,
  previouslySortRating,
  openFilter,
}) => {
  const handleSortOptionClick = (option) => {
    onSelect(option);
  };
  const dispatch = useDispatch();

  // Create a ref to the component
  const sortBySelectRef = useRef(null);

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleClickOutside = (event) => {
    if (
      sortBySelectRef.current &&
      !sortBySelectRef.current.contains(event.target)
    ) {
      // Click occurred outside of the BrandSelect component
      setOpenFilter(null);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenFilter(open);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openFilter === "sortBy"}
      onClose={toggleDrawer(null)}
      onOpen={toggleDrawer("sortBy")}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          overflow: "visible",
        },
      }}
    >
      <div className="w-full flex flex-col bg-white-1 rounded-t-[15px]">
        <div className="w-full flex items-center justify-between py-2 px-3 border-b-2 border-black-1/10">
          <span className="text-16 font-bold tracking-wide text-blue">
            Sort By
          </span>
          <CloseSharpIcon
            onClick={() => setOpenFilter(null)}
            style={{ fontSize: "18px" }}
            className="text-blue"
          />
        </div>
        <div className="w-full flex flex-row flex-wrap py-2 px-4">
          <span
            className={`w-full h-[45px] py-2 px-3 text-16 font-bold rounded-sm my-1 tracking-wide flex items-center ${
              !previouslySortPrice && !previouslySortRating
                ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
            }`}
            onClick={() => {
              dispatch(setSortPrice(""));
              dispatch(setSortRating(""));
              handleSortOptionClick("relevance");
            }}
          >
            Relevance
          </span>
          <div
            className={`w-full h-[45px] py-1 px-1 pl-3 text-16 font-bold rounded-sm my-1 tracking-wide flex justify-between items-center ${
              previouslySortPrice ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
            }`}
          >
            <span>Price</span>
            <div
              className={`w-[35%] h-full flex items-center justify-between  rounded-sm p-1 ${
                previouslySortPrice ? "bg-white-1 text-black-1/80" : "border-[1px] border-black-1/10"
              }`}
            >
              <img loading="lazy"
                src={previouslySortPrice === "desc" ? downIconWhite : downIcon}
                alt="desc-logo"
                className={` w-[30px] height-full rounded-sm  py-1 px-1 ${
                  previouslySortPrice === "desc" ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
                }`}
                onClick={() => {
                  dispatch(setSortPrice("desc"));
                  dispatch(setSortRating(""));
                  handleSortOptionClick("a-z-price");
                }}
              />
              <img loading="lazy"
                src={previouslySortPrice === "asc" ? upIconWhite : upIcon}
                alt="asc-logo"
                className={` w-[30px] height-full rounded-sm  py-1 px-1 ${
                  previouslySortPrice === "asc" ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
                }`}
                onClick={() => {
                  dispatch(setSortPrice("asc"));
                  dispatch(setSortRating(""));
                  handleSortOptionClick("z-a-price");
                }}
              />
            </div>
          </div>
          <div
            className={`w-full h-[45px] py-1 px-1 pl-3 text-16 font-bold rounded-sm my-1 tracking-wide flex justify-between items-center ${
              previouslySortRating ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
            }`}
          >
            <span>Rating</span>
            <div
              className={`w-[35%] h-full flex items-center justify-between  rounded-sm p-1 ${
                previouslySortRating ? "bg-white-1 text-black-1/80" : "border-[1px] border-black-1/10"
              }`}
            >
              <img loading="lazy"
                src={previouslySortRating === "desc" ? downIconWhite : downIcon}
                alt="desc-logo"
                className={`w-[30px] height-full rounded-sm py-1 px-1 ${
                  previouslySortRating === "desc" ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
                }`}
                onClick={() => {
                  dispatch(setSortRating("desc"));
                  dispatch(setSortPrice(""));
                  handleSortOptionClick("a-z-rating");
                }}
              />
              <img loading="lazy"
                src={previouslySortRating === "asc" ? upIconWhite : upIcon}
                alt="asc-logo"
                className={`w-[30px] height-full rounded-sm  py-1 px-1 ${
                  previouslySortRating === "asc" ? "bg-green text-white-1"
                : "bg-white-1 text-black-1/80"
                }`}
                onClick={() => {
                  dispatch(setSortRating("asc"));
                  dispatch(setSortPrice(""));
                  handleSortOptionClick("z-a-rating");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default SortBy;
