import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrderStatus, getInventory, getAllInventory, createOrder,freezeQuantity, cancelOrder, createPayOrder,getAllOrder,viewOrderDetail, updatePaymentStatus } from "./OrderAPI";

const initialState = {
  orderStatus: null,
  inventory: [],
  orders:[],
  allInventory: [],
  status: "idle",
  error: null,
  orderDetail:[],
};

// Async thunks for order-related actions
export const getOrderStatusAsync = createAsyncThunk(
  "order/getOrderStatus",
  async (orderNumber) => {
    try {
      const response = await getOrderStatus(orderNumber);
      return response.data;
    } catch (error) {
      throw Error("Failed to get order status");
    }
  }
);

export const viewOrderDetailAsync = createAsyncThunk(
  "order/viewOrderDetail",
  async (order_ID) => {
    try {
      const response = await viewOrderDetail(order_ID);
      return response.data;
    } catch (error) {
      throw Error("Failed to get order status");
    }
  }
);

export const getInventoryAsync = createAsyncThunk(
  "order/getInventory",
  async (itemNo) => {
    try {
      const response = await getInventory(itemNo);
      return response.data;
    } catch (error) {
      throw Error("Failed to get inventory");
    }
  }
);

export const getAllInventoryAsync = createAsyncThunk(
  "order/getAllInventory",
  async () => {
    try {
      const response = await getAllInventory();
      return response.data;
    } catch (error) {
      throw Error("Failed to get all inventory");
    }
  }
);

export const getAllOrderAsync = createAsyncThunk(
  "order/getAllOrder",
  async (userID) => {
    try {
      const response = await getAllOrder(userID);
      return response.data;
    } catch (error) {
      throw Error("Failed to get all inventory");
    }
  }
);

export const createOrderAsync = createAsyncThunk(
  "order/createOrder",
  async (orderData) => {
    try {
      const response = await createOrder(orderData);
      return response;
    } catch (error) {
      throw Error("Failed to create order");
    }
  }
);
export const freezeQuantityAsync = createAsyncThunk(
  "order/freezeQuantity",
  async (quantityData) => {
    try {
      const response = await freezeQuantity(quantityData);
      return response;
    } catch (error) {
      throw Error("Failed to Freeze quantity");
    }
  }
);

export const cancelOrderAsync = createAsyncThunk(
  "order/cancelOrder",
  async (orderData) => {
    try {
      const response = await cancelOrder(orderData);
      return response;
    } catch (error) {
      throw Error("Failed to cancel order");
    }
  }
);

export const createPayOrderAsync = createAsyncThunk(
  "order/createPayOrder",
  async (orderData) => {
    try {
      const response = await createPayOrder(orderData);
      return response;
    } catch (error) {
      throw Error("Failed to create payment order");
    }
  }
);

export const updatePaymentStatusAsync = createAsyncThunk(
  "order/updatePaymentStatus",
  async (transactionId) => {
    try {
      const response = await updatePaymentStatus(transactionId);
      return response;
    } catch (error) {
      throw Error("Failed to create payment order");
    }
  }
);


export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get order status
      .addCase(getOrderStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOrderStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.orderStatus = action.payload;
      })
      // Get inventory
      .addCase(getInventoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInventoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.inventory = action.payload;
      })
      // Get all inventory
      .addCase(getAllInventoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllInventoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.allInventory = action.payload;
      })
      // Create order
      .addCase(createOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle success, if needed
      })
      .addCase(freezeQuantityAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(freezeQuantityAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle success, if needed
      })
      .addCase(getAllOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.orders = action.payload;
      })
      // Cancel order
      .addCase(cancelOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle success, if needed
      })
      // Create payment order
      .addCase(createPayOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPayOrderAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // Handle success, if needed
      })
      .addCase(viewOrderDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(viewOrderDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.orderDetail = action.payload;
      })
      .addCase(updatePaymentStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePaymentStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.orderDetail = action.payload;
      });
  },
});

export const selectOrderStatus = (state) => state.order.orderStatus;
export const selectInventory = (state) => state.order.inventory;
export const selectAllInventory = (state) => state.order.allInventory;
export const selectAllOrders = (state) => state.order.orders;
export const selectViewOrderDetail = (state) => state.order.orderDetail;


export default orderSlice.reducer;
