import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import {
  fetchAllAdminUsersAsync,
  fetchAllAdminUsersOrdersAsync,
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllAdminUser,
  selectAllAdminUserOrders,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";

function AdminOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUser);
  const userOrders = useSelector(selectAllAdminUserOrders);
  // console.log(users, userOrders);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [openOrderProduct, setOpenOrderProduct] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    // console.log(searchQuery);
    if (users) {
      setFilteredUsers(
        users.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            user?.LastName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            user?.PhoneNumber?.includes(searchQuery) || 
            user?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "order_count") {
        sortedUsers.sort((a, b) => b?.OrderCount - a?.OrderCount);
      }
      else if(sortCriteria === "state_name"){
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  // console.log("FilteredUser",filteredUsers);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllAdminUsersOrdersAsync(UserID)).then(() => {
      handleClose();
    });
  };

  const calculateTotalOrder = () => {
    let totalOrder = 0;
    for (const order of users) {
      totalOrder += order?.OrderCount;
    }
    return totalOrder;
  };

  const handleDownloadInvoice = async (id) => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${
        id && id
      }.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  return (
    <div className="admin-dashboard-right-pre-order-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="admin-dashboard-right-top-heading">
        <span className="admin-dashboard-heading-name">Orders</span>
        <img loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="admin-logo"
        />
      </div>
      <div className="admin-dashboard-main-container">
        <div className="admin-dashboard-middle-container">
          <div className="admin-dashboard-total-preorder-container">
            <span className="admin-dashboard-heading-total">Total Orders</span>
            <span className="admin-dashboard-total-count">
              {users && Array.isArray(users) ? calculateTotalOrder() : 0}
            </span>
            <Inventory2OutlinedIcon />
          </div>
          <div className="admin-dashboard-sort-by-search-container">
            <div className="admin-dashboard-sort-by">
              <span className="admin-dashboard-sort-by-name">Sort by:</span>
              <select
                name="sort-by"
                id="admin-dashboard-sort-by-id"
                value={sortCriteria}
                onChange={(e) => setSortCriteria(e.target.value)}
              >
                <option value="">None</option>
                <option value="last_login">Last Login</option>
                <option value="order_count">Order Count</option>
                <option value="state_name">State</option>
              </select>
            </div>
            <div className="admin-dashboard-middle-search-container">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search by name, phone or state"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="admin-dashboard-search-input"
              />
            </div>
          </div>
          {/* <div
            className="admin-dashboard-middle-search-container"
            style={{ position: "relative", top: "20px", right: "75px" }}
          >
            <SearchIcon />
            <input
              type="text"
              placeholder="Search by name or phone"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="admin-dashboard-search-input"
            />
          </div> */}
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="admin-dashboard-main-user-preorder-container">
            <span className="admin-dashboard-main-user-preorder-heading">
              Recent Orders
            </span>
            <div className="admin-dashboard-main-user-item-head">
              <span className="admin-dashboard-serial-no increase-so">
                S.No
              </span>
              <span className="admin-dashboard-item-each-head increase-widths">
                Last Login
              </span>
              <span className="admin-dashboard-item-each-head increase-width">
                User Name
              </span>
              <span className="admin-dashboard-item-each-head increase-width">
                Phone Number
              </span>
              <span className="admin-dashboard-item-each-head increase-width">
                Business Name
              </span>
              <span className="admin-dashboard-item-each-head increase-width">
                State
              </span>
              <span className="admin-dashboard-item-each-head increase-width">
                Order Count
              </span>
            </div>
            <div className="admin-dashboard-main-user-prebook-container">
              {filteredUsers &&
                Array.isArray(filteredUsers) &&
                filteredUsers?.map((user, index) => (
                  <div className="admin-dashboard-main-each-user" key={index}>
                    <div
                      className="admin-dashboard-main-each-user-top"
                      style={{
                        boxShadow:
                          user?.UserID === openOrder &&
                          "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <span className="admin-dashboard-user-sno increase-so">
                        {index + 1}
                      </span>
                      <span className="admin-user-each-attr increase-widths">
                        {user?.LastLogin !== null ? (
                          new Date(user?.LastLogin).toLocaleDateString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                            }
                          )
                        ) : (
                          <b
                            style={{
                              color: "var(--color-peach)",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            Not Login Yet
                          </b>
                        )}
                      </span>
                      <span className="admin-user-each-attr increase-width">
                        {user?.FirstName}&nbsp;{user?.LastName}
                      </span>
                      <span className="admin-user-each-attr increase-width">
                        {user?.PhoneNumber}
                      </span>
                      <span className="admin-user-each-attr increase-width">
                        {user?.business_name}
                      </span>
                      <span className="admin-user-each-attr increase-width">
                        {user?.stateName}
                      </span>
                      <span className="admin-user-each-attr increase-width">
                        <span style={{ width: "20px", textAlign: "center" }}>
                          {user?.OrderCount}
                        </span>
                        {openOrder === user?.UserID ? (
                          <span
                            className="open-btn-admin"
                            style={{
                              background: "var(--color-peach)",
                            }}
                            onClick={() => setOpenOrder(null)}
                          >
                            Close
                          </span>
                        ) : (
                          <span
                            className="open-btn-admin"
                            style={{
                              background: "var(--color-green)",
                            }}
                            onClick={() => {
                              setOpenOrder(user?.UserID);
                              findAllOrderUsingUserID(user?.UserID);
                            }}
                          >
                            Open
                          </span>
                        )}
                      </span>
                    </div>

                    {openOrder === user?.UserID && (
                      <div className="admin-dashboard-main-user-item-head color-gray">
                        <span
                          className="admin-dashboard-serial-no increase-so"
                          style={{ width: "18%" }}
                        >
                          Order ID
                        </span>
                        <span
                          className="admin-dashboard-item-each-head increase-width"
                          style={{ width: "19%" }}
                        >
                          Order Date
                        </span>
                        <span
                          className="admin-dashboard-item-each-head increase-width"
                          style={{ width: "19%" }}
                        >
                          Discount
                        </span>
                        <span
                          className="admin-dashboard-item-each-head increase-width"
                          style={{ width: "19%" }}
                        >
                          Amount
                        </span>
                        <span
                          className="admin-dashboard-item-each-head increase-width"
                          style={{ width: "19%" }}
                        >
                          Total Item Count
                        </span>
                        <span
                          className="admin-dashboard-item-each-head increase-width"
                          style={{ width: "6%" }}
                        >
                          Invoice
                        </span>
                      </div>
                    )}
                    {openOrder === user?.UserID &&
                      userOrders?.length > 0 &&
                      userOrders
                        ?.slice()
                        .sort(
                          (a, b) =>
                            new Date(b?.OrderDate) - new Date(a?.OrderDate)
                        )
                        .map((orders, index) => (
                          <div
                            className="admin-dashboard-main-each-user-top"
                            style={{
                              boxShadow:
                                user?.UserID === openOrder &&
                                "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                            }}
                            key={index}
                          >
                            <span
                              className="admin-dashboard-user-sno increase-so"
                              style={{ width: "18%" }}
                            >
                              {orders?.OrderNumber}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{ width: "19%" }}
                            >
                              {orders?.OrderDate &&
                                new Date(orders?.OrderDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{ width: "19%" }}
                            >
                              ₹
                              {orders?.DiscountAmount === null
                                ? formatWithCommasWithDecimal(0)
                                : formatWithCommasWithDecimal(
                                    orders?.DiscountAmount
                                  )}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{ width: "19%" }}
                            >
                              ₹
                              {orders?.Amount === null
                                ? formatWithCommasWithDecimal(0)
                                : formatWithCommasWithDecimal(orders?.Amount)}
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{ width: "19%" }}
                            >
                              <span
                                style={{ width: "15px", textAlign: "center" }}
                              >
                                {orders?.TotalItemCount}
                              </span>
                              <span
                                className="admin-order-view-1"
                                onClick={() =>
                                  window.open(
                                    `/user/orders/view_order/${orders?.OrderNumber}`,
                                    "_blank"
                                  )
                                }
                              >
                                View
                              </span>
                            </span>
                            <span
                              className="admin-user-each-attr increase-width"
                              style={{ width: "6%" }}
                            >
                              <CloudDownloadIcon
                                style={{
                                  fontSize: "25px",
                                  color: "var(--color-peach)",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownloadInvoice(orders?.OrderNumber)
                                }
                              />
                            </span>
                          </div>
                        ))}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminOrders;
