import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDealersPrductDetails,
  fetchPrductDetails,
} from "./productDescAPI";

const initialState = {
  productDetails: [],
  status: "idle",
  selectedProd_ID: null,
  selectedVariant: [],
};

export const fetchProductDetailsAsync = createAsyncThunk(
  "productDetail/fetchProductDetails",
  async (prod_ID) => {
    const response = await fetchPrductDetails(prod_ID);

    return response.data;
  }
);
export const fetchDealerProductDetailsAsync = createAsyncThunk(
  "productDetail/fetchDealerProductDetails",
  async (prod_ID) => {
    const response = await fetchDealersPrductDetails(prod_ID);

    return response.data;
  }
);

export const setSelectedProd_ID = (prod_ID) => (dispatch) => {
  dispatch(productDetailSlice.actions.setSelectedProd_ID(prod_ID));
};

export const productDetailSlice = createSlice({
  name: "productDetail",
  initialState,
  reducers: {
    setSelectedProd_ID: (state, action) => {
      state.selectedProd_ID = action.payload;
    },
    setSelectedVariant: (state, action) => {
      state.selectedVariant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productDetails = action.payload;
      })
      .addCase(fetchDealerProductDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDealerProductDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productDetails = action.payload;
      });
  },
});

export const selectProductDetails = (state) =>
  state.productDetail.productDetails;

export const { setSelectedVariant } = productDetailSlice.actions;

export default productDetailSlice.reducer;
