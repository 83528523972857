import { API_BASE_URL } from "../../config";
export function fetchAllCarts(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}fetch_cart/${UserID}`);
    const data = await response.json();

    resolve({ data });
  });
}
export function addToCarts(cartData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}cart/${cartData?.userID}/${cartData?.SKU}/${cartData?.buyQty}`
      );
      if (!response.ok) {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function removeFromCarts(cartData) {
  const userID = cartData.userID;
  const SKU_ID = cartData.SKU;
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}remove_cart/${userID}/${SKU_ID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}
export function removeAllFromCarts(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}remove_all_cart/${UserID}`);
    const data = await response.json();

    resolve({ data });
  });
}
export function changeQtyOfProductCarts(cartData) {
  const UserID = cartData.userID;
  const SKU_ID = cartData.SKU;
  const Action = cartData.Action;

  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}update_quantity/${UserID}/${SKU_ID}/${Action}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

// PREORDER   ----------------------------------------

export function fetchAllPreOrderCarts(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}preorder/fetch/${UserID}`);
    const data = await response.json();

    resolve({ data });
  });
}
export function addToPreOrderCarts(preOrderData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}preorder/addOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // You might need other headers like authorization token, etc.
        },
        body: JSON.stringify(preOrderData), // Convert preOrderData to JSON string
      });

      if (!response.ok) {
        // Handle non-successful responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function removeFromPreOrderCarts(SKU_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}preorder/delete/${SKU_ID}`);
    const data = await response.json();

    resolve({ data });
  });
}
export function removeAllFromPreorderCarts(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}preorder/all_delete/${UserID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

// PREORDER ADMIN -------------

export function fetchAllPreOrderUserDetails() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}preorder/all_user_details`);
    const data = await response.json();

    resolve({ data });
  });
}

export function fetchAllAdminUsers() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/users`);
    const data = await response.json();

    resolve({ data });
  });
}

export function fetchAllAdminUsersOrders(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/orders/${UserID}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function fetchAllAdminUsersCarts() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/cart_users`);
    const data = await response.json();

    resolve({ data });
  });
}

export function fetchAllUserCarts(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}fetch_cart/${UserID}`);
    const data = await response.json();

    resolve({ data });
  });
}

// Switch to Admin

export function fetchAllUsersRole() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/fetch_usertype`);
    const data = await response.json();

    resolve({ data });
  });
}
export function fetchUserRoleById(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}list/fetch_userRole/${UserID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

export function updateUserRole(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}list/update_usertype/${UserID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

// Switch to shipping

export function fetchAllUsersShipping() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/shpping_users`);
    const data = await response.json();

    resolve({ data });
  });
}
export function fetchUserShippingById(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}list/shpping_user/${UserID}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function updateUserShipping(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}list/update_shpping_user/${UserID}`
    );
    const data = await response.json();

    resolve({ data });
  });
}
