import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatWithCommasWithDecimal,
  getFormattedDueDate,
} from "../../utils/utils";
import discountLogo from "../../assets/Icon-Discount.png";
import { generatePartialPaymentUrl } from "../../utils/payment";
import PaymentMain from "../PartialPayment/PaymentMain";

const DueAmount = ({ creditDetail, recentOrder, userDetail }) => {
  const [amount, setAmount] = useState(0);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [openPaymentModel, setOpenPaymentModal] = useState(false);
  const [pendingOrder, setPendingOrder] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    if (recentOrder?.length > 0) {
      const pending = recentOrder
        ?.filter((order) => order?.transactionType === "SPEND")
        ?.sort(
          (a, b) => new Date(a?.transactionDate) - new Date(b?.transactionDate)
        )[0];

      setPendingOrder(pending);

      if (pending) {
        const dueDate = getFormattedDueDate(pending?.transactionDate);
        setDueDate(dueDate);
      }
    }
  }, [recentOrder]);

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;

    // Allow clearing the input
    if (inputValue === "") {
      setAmount(0);
      return;
    }

    const numericValue = parseInt(inputValue);

    // Prevent setting the value if it's NaN or less than 0, or greater than the available spentAmount
    if (
      !isNaN(numericValue) &&
      numericValue >= 0 &&
      numericValue <= (creditDetail?.spentAmount || 0)
    ) {
      setAmount(numericValue);
    }
  };

  const handlePresetAmountClick = (amount) => {
    if (parseInt(amount) > 0 && parseInt(amount) <= creditDetail?.spentAmount) {
      setAmount(parseInt(amount));
    } else if (
      parseInt(amount) > 0 &&
      parseInt(amount) > creditDetail?.spentAmount
    ) {
      setAmount(parseInt(creditDetail?.spentAmount));
    }
  };

  const TargetPartialPayment = async () => {
    if (parseInt(amount) > 0) {
      setOpenPaymentModal(true);
      await generatePartialPaymentUrl(
        amount,
        userDetail,
        setPaymentSessionId,
        setTransactionId
      );
    }
  };

  return (
    <>
      <div className="w-full h-auto flex flex-col bg-white-1 shadow-sm shadow-black-1/10 mb-3 rounded-md p-3">
        <span className="w-full h-[40px] text-[18px] pt-1  text-black-1 font-bold tracking-wide border-b-[1.5px] border-black-1/10">
          Pay Due Amount
        </span>
        <div className="w-full flex flex-col lg:flex-row justify-between">
          {/* Discount Section */}
          <div className="w-full lg:w-[50%] flex flex-col justify-between py-2">
            {pendingOrder ? (
              <div className="w-full sm:w-fit flex items-center p-2 bg-[#28a447]/10 rounded-md">
                <img
                  loading="lazy"
                  src={discountLogo}
                  alt="discount-logo"
                  className="h-[30px]"
                />
                <div className="flex flex-col p-2">
                  <span className="text-[11px] text-black-1 font-bold my-[2px]">
                    2% Discount
                  </span>
                  <span className="text-[10.5px] text-black-1/80 font-semibold my-[2px]">
                    Pay only ₹
                    {formatWithCommasWithDecimal(pendingOrder?.amount)} by
                    paying before {dueDate}
                  </span>
                </div>
              </div>
            ) : (
              <div className="w-full sm:w-fit flex items-center p-2 bg-gray-200 rounded-md">
                <img
                  loading="lazy"
                  src={discountLogo}
                  alt="no-discount-logo"
                  className="h-[30px] opacity-50"
                />
                <div className="flex flex-col p-2">
                  <span className="text-[11px] text-gray-600 font-bold my-[2px]">
                    No Pending Payments
                  </span>
                  <span className="text-[10.5px] text-gray-500 font-semibold my-[2px]">
                    You have no pending orders at the moment.
                  </span>
                </div>
              </div>
            )}
            <span className="text-[10.5px] text-black-1/70 mt-2">
              <b>Note:</b> Post bill payments, the updated amount due will
              reflect shortly.
            </span>
          </div>

          {/* Payment Section */}
          <div className="w-full lg:w-[45%] flex flex-col items-end py-3 mt-4 lg:mt-0">
            <div className="w-full flex flex-col">
              <span className="text-[13px] text-black-1/70">
                Enter the amount you want to pay
              </span>
              <div className="flex gap-2 flex-wrap my-2 mb-1">
                <button
                  onClick={() => handlePresetAmountClick(2000)}
                  className="text-[10px] text-gray-500 font-sans border-[1.5px] border-gray-500 rounded-[30px] py-[3px] px-2 tracking-wide cursor-pointer"
                >
                  ₹2000.00
                </button>
                <button
                  onClick={() => handlePresetAmountClick(4000)}
                  className="text-[10px] text-gray-500 font-sans border-[1.5px] border-gray-500 rounded-[30px] py-[3px] tracking-wide px-2 cursor-pointer max-sm:hidden"
                >
                  ₹4000.00
                </button>
                <button
                  onClick={() => handlePresetAmountClick(5000)}
                  className="text-[10px] text-gray-500 font-sans border-[1.5px] border-gray-500 rounded-[30px] py-[3px] px-2 tracking-wide cursor-pointer"
                >
                  ₹5000.00
                </button>
                <button
                  onClick={() => handlePresetAmountClick(10000)}
                  className="text-[10px] text-gray-500 font-sans border-[1.5px] border-gray-500 rounded-[30px] py-[3px] tracking-wide px-2 cursor-pointer"
                >
                  ₹10,000.00
                </button>
                <button
                  onClick={() =>
                    handlePresetAmountClick(parseInt(creditDetail?.spentAmount))
                  }
                  className="text-[10px] whitespace-nowrap text-gray-500 font-sans border-[1.5px] border-gray-500 rounded-[30px] py-[3px] tracking-wide px-2 cursor-pointer"
                >
                  Pay full Amount
                </button>
              </div>

              <TextField
                variant="outlined"
                value={amount}
                type="number"
                onChange={handleAmountChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                  },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "100%",
                  height: "35px",
                  backgroundColor: "#28a447",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#28a447",
                  },
                }}
                onClick={TargetPartialPayment}
              >
                Pay
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Modal */}
      {openPaymentModel && (
        <PaymentMain
          openPaymentModel={openPaymentModel}
          setOpenPaymentModal={setOpenPaymentModal}
          transactionId={transactionId}
          paymentSessionId={paymentSessionId}
          userDetail={userDetail}
          amount={amount}
        />
      )}
    </>
  );
};

export default DueAmount;
