import { fetchUserShippingById } from "../features/cartPage/cartPageAPI";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";

export const colourNameToHex = (colour) => {
  const colours = {
    aliceblue: "#f0f8ff",
    antiquewhite: "#faebd7",
    aqua: "#00ffff",
    aquamarine: "#7fffd4",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    bisque: "#ffe4c4",
    black: "#000000",
    blanchedalmond: "#ffebcd",
    blue: "#0000ff",
    blueviolet: "#8a2be2",
    brown: "#a52a2a",
    burlywood: "#deb887",
    cadetblue: "#5f9ea0",
    chartreuse: "#7fff00",
    chocolate: "#d2691e",
    coral: "#ff7f50",
    cornflowerblue: "#6495ed",
    cornsilk: "#fff8dc",
    crimson: "#dc143c",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgoldenrod: "#b8860b",
    darkgray: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkseagreen: "#8fbc8f",
    darkslateblue: "#483d8b",
    darkslategray: "#2f4f4f",
    darkturquoise: "#00ced1",
    darkviolet: "#9400d3",
    deeppink: "#ff1493",
    deepskyblue: "#00bfff",
    dimgray: "#696969",
    dodgerblue: "#1e90ff",
    firebrick: "#b22222",
    floralwhite: "#fffaf0",
    forestgreen: "#228b22",
    fuchsia: "#ff00ff",
    gainsboro: "#dcdcdc",
    ghostwhite: "#f8f8ff",
    gold: "#ffd700",
    goldenrod: "#daa520",
    gray: "#808080",
    green: "#008000",
    greenyellow: "#adff2f",
    honeydew: "#f0fff0",
    hotpink: "#ff69b4",
    "indianred ": "#cd5c5c",
    indigo: "#4b0082",
    ivory: "#fffff0",
    khaki: "#f0e68c",
    lavender: "#e6e6fa",
    lavenderblush: "#fff0f5",
    lawngreen: "#7cfc00",
    lemonchiffon: "#fffacd",
    lightblue: "#add8e6",
    lightcoral: "#f08080",
    lightcyan: "#e0ffff",
    lightgoldenrodyellow: "#fafad2",
    lightgrey: "#d3d3d3",
    lightgreen: "#90ee90",
    lightpink: "#ffb6c1",
    lightsalmon: "#ffa07a",
    lightseagreen: "#20b2aa",
    lightskyblue: "#87cefa",
    lightslategray: "#778899",
    lightsteelblue: "#b0c4de",
    lightyellow: "#ffffe0",
    lime: "#00ff00",
    limegreen: "#32cd32",
    linen: "#faf0e6",
    magenta: "#ff00ff",
    maroon: "#800000",
    mediumaquamarine: "#66cdaa",
    mediumblue: "#0000cd",
    mediumorchid: "#ba55d3",
    mediumpurple: "#9370d8",
    mediumseagreen: "#3cb371",
    mediumslateblue: "#7b68ee",
    mediumspringgreen: "#00fa9a",
    mediumturquoise: "#48d1cc",
    mediumvioletred: "#c71585",
    midnightblue: "#191970",
    mintcream: "#f5fffa",
    mistyrose: "#ffe4e1",
    moccasin: "#ffe4b5",
    navajowhite: "#ffdead",
    navy: "#000080",
    oldlace: "#fdf5e6",
    olive: "#808000",
    olivedrab: "#6b8e23",
    orange: "#ffa500",
    orangered: "#ff4500",
    orchid: "#da70d6",
    palegoldenrod: "#eee8aa",
    palegreen: "#98fb98",
    paleturquoise: "#afeeee",
    palevioletred: "#d87093",
    papayawhip: "#ffefd5",
    peachpuff: "#ffdab9",
    peru: "#cd853f",
    pink: "#ffc0cb",
    plum: "#dda0dd",
    powderblue: "#b0e0e6",
    purple: "#800080",
    rebeccapurple: "#663399",
    red: "#ff0000",
    rosybrown: "#bc8f8f",
    royalblue: "#4169e1",
    saddlebrown: "#8b4513",
    salmon: "#fa8072",
    sandybrown: "#f4a460",
    seagreen: "#2e8b57",
    seashell: "#fff5ee",
    sienna: "#a0522d",
    silver: "#c0c0c0",
    skyblue: "#87ceeb",
    slateblue: "#6a5acd",
    slategray: "#708090",
    snow: "#fffafa",
    springgreen: "#00ff7f",
    steelblue: "#4682b4",
    tan: "#d2b48c",
    teal: "#008080",
    thistle: "#d8bfd8",
    tomato: "#ff6347",
    turquoise: "#40e0d0",
    violet: "#ee82ee",
    wheat: "#f5deb3",
    white: "#ffffff",
    whitesmoke: "#f5f5f5",
    yellow: "#ffff00",
    yellowgreen: "#9acd32",
    "stone white matt": "#EAEAEA",
    "black matt": "#1E1E1E",
    "black-anthracite matt": "#2B2B2B",
    "white pink glossy": "#FFDDFF",
    "neon yellow glossy": "#FFFF33",
    "neon yellow - black glossy": "#E3E100",
    "white glossy": "#F8F8F8",
    "white black glossy": "#1C1C1C",
    "grey matt": "#999999",
    "black blue matt": "#23234D",
    "black lime matt": "#34563D",
    "black red matt": "#3B2323",
    "ocean blue matt": "#335656",
    "black finish w// decal kit": "#242424",
    "blast grey": "#808080",
    "blast orange": "#FFB347",
    "blast black": "#1C1C1C",
    "blast red": "#FF4C4C",
    grey: "#808080",
    "bead blast black w/mirror polish": "#0F0F0F",
    "bead blast orange w/mirror polish": "#6C3F00",
    "bead blast red w/mirror polish": "#4C0C0C",
    "ano. black": "#1C1C1C",
    "anod. black": "#1C1C1C",
    "anod. red": "#B30000",
    "blast silver": "#C0C0C0",
    "blast blue": "#0000FF",
    granite: "#828282",
  };

  return colours[colour?.toLowerCase()] || colour?.toLowerCase() || "";
};

export const calculateDealerPrice = (MRP_price, NDP_Price, gst) => {
  const gstPrice = (NDP_Price * gst) / 100;
  return NDP_Price + gstPrice;
};

export const calculateOfferPrice = (MRP_price, Offer_price, gst) => {
  const gstPrice = (Offer_price * gst) / 100;
  return !isNaN(Offer_price + gstPrice) ? Offer_price + gstPrice : "";
};

export function calculateDiscountPercentage(MRP_price, NDP_price) {
  const discountAmount = MRP_price - NDP_price;
  let discountPercentage = (discountAmount / MRP_price) * 100;

  const decimalPart = discountPercentage % 1;

  if (decimalPart < 0.5) {
    discountPercentage = Math.floor(discountPercentage);
  } else {
    discountPercentage = Math.ceil(discountPercentage);
  }

  return parseInt(discountPercentage);
}

export const formatWithCommasWithDecimal = (number) => {
  const formattedNumber = parseInt(number).toFixed(2); // Convert to float to handle decimal numbers
  const parts = formattedNumber.split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] ? `.${parts[1]}` : ""; // Check if decimal part exists

  let formattedIntegerPart = "";
  if (integerPart.length <= 3) {
    formattedIntegerPart = integerPart;
  } else if (integerPart.length <= 5) {
    formattedIntegerPart =
      integerPart.substring(0, integerPart.length - 3) +
      "," +
      integerPart.substring(integerPart.length - 3);
  } else if (integerPart.length <= 7) {
    formattedIntegerPart =
      integerPart.substring(0, integerPart.length - 5) +
      "," +
      integerPart.substring(integerPart.length - 5, integerPart.length - 3) +
      "," +
      integerPart.substring(integerPart.length - 3);
  }

  return `${formattedIntegerPart}${decimalPart}`;
};

export const formatWithCommasWithoutDecimal = (number) => {
  const formattedNumber = parseInt(number).toFixed(2); // Convert to float to handle decimal numbers
  const parts = formattedNumber.split(".");
  const integerPart = parts[0];

  let formattedIntegerPart = "";
  if (integerPart.length <= 3) {
    formattedIntegerPart = integerPart;
  } else if (integerPart.length <= 5) {
    formattedIntegerPart =
      integerPart.substring(0, integerPart.length - 3) +
      "," +
      integerPart.substring(integerPart.length - 3);
  } else if (integerPart.length <= 7) {
    formattedIntegerPart =
      integerPart.substring(0, integerPart.length - 5) +
      "," +
      integerPart.substring(integerPart.length - 5, integerPart.length - 3) +
      "," +
      integerPart.substring(integerPart.length - 3);
  }

  return `${formattedIntegerPart}`;
};

export const truncateText = (text, maxLength) => {
  return text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const getStateId = (stateName) => {
  switch (stateName) {
    case "Andhra Pradesh":
      return "1";
    case "Arunachal Pradesh":
      return "2";
    case "Assam":
      return "3";
    case "Bihar":
      return "4";
    case "Chhattisgarh":
      return "5";
    case "Goa":
      return "6";
    case "Gujarat":
      return "7";
    case "Haryana":
      return "8";
    case "Himachal Pradesh":
      return "9";
    case "Jharkhand":
      return "10";
    case "Karnataka":
      return "11";
    case "Kerala":
      return "12";
    case "Madhya Pradesh":
      return "13";
    case "Maharashtra":
      return "14";
    case "Manipur":
      return "15";
    case "Meghalaya":
      return "16";
    case "Mizoram":
      return "17";
    case "Nagaland":
      return "18";
    case "Odisha":
      return "19";
    case "Punjab":
      return "20";
    case "Rajasthan":
      return "21";
    case "Sikkim":
      return "22";
    case "Tamil Nadu":
      return "23";
    case "Telangana":
      return "24";
    case "Tripura":
      return "25";
    case "Uttar Pradesh":
      return "26";
    case "Uttarakhand":
      return "27";
    case "West Bengal":
      return "28";
    case "Chandigarh":
      return "29";
    case "Delhi":
      return "30";
    case "Ladakh":
      return "31";
    case "Jammu and Kashmir":
      return "32";
    default:
      return "";
  }
};

export const getStateName = (stateId) => {
  switch (stateId) {
    case "1":
      return "Andhra Pradesh";
    case "2":
      return "Arunachal Pradesh";
    case "3":
      return "Assam";
    case "4":
      return "Bihar";
    case "5":
      return "Chhattisgarh";
    case "6":
      return "Goa";
    case "7":
      return "Gujarat";
    case "8":
      return "Haryana";
    case "9":
      return "Himachal Pradesh";
    case "10":
      return "Jharkhand";
    case "11":
      return "Karnataka";
    case "12":
      return "Kerala";
    case "13":
      return "Madhya Pradesh";
    case "14":
      return "Maharashtra";
    case "15":
      return "Manipur";
    case "16":
      return "Meghalaya";
    case "17":
      return "Mizoram";
    case "18":
      return "Nagaland";
    case "19":
      return "Odisha";
    case "20":
      return "Punjab";
    case "21":
      return "Rajasthan";
    case "22":
      return "Sikkim";
    case "23":
      return "Tamil Nadu";
    case "24":
      return "Telangana";
    case "25":
      return "Tripura";
    case "26":
      return "Uttar Pradesh";
    case "27":
      return "Uttarakhand";
    case "28":
      return "West Bengal";
    case "29":
      return "Chandigarh";
    case "30":
      return "Delhi";
    case "31":
      return "Ladakh";
    case "32":
      return "Jammu and Kashmir";
    default:
      return "";
  }
};

export const formatDate = (updateDate) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(updateDate).toLocaleDateString("en-US", options);
};

export const calculateSpentPercentage = (totalAmount, spentAmount) => {
  if (totalAmount === 0) return 0; // Avoid division by zero
  return parseInt((spentAmount / totalAmount) * 100);
};

// Function to calculate the total amount for all product in the cart without GST.
export const calculateTotalAmountWithOutGST = (cart) => {
  let totalAmount = 0;
  cart &&
    cart?.forEach((product) => {
      totalAmount +=
        !product?.skuDetails?.SKU_ID?.startsWith("D") &&
        (product.skuDetails?.Offer_price
          ? product.skuDetails?.Offer_price
          : product?.skuDetails?.NDP_Price) * product?.buy_quantity;
    });
  return parseInt(totalAmount);
};

// Function to calculate the total amount for all products in the cart
export const calculateTotalAmount = (cart) => {
  let totalAmount = 0;
  cart &&
    cart?.forEach((product) => {
      totalAmount +=
        !product?.skuDetails?.SKU_ID?.startsWith("D") &&
        (product.skuDetails?.Offer_price
          ? calculateOfferPrice(
              parseInt(product?.skuDetails?.MRP_price),
              parseInt(product?.skuDetails?.Offer_price),
              parseInt(product?.skuDetails?.GST)
            )
          : calculateDealerPrice(
              parseInt(product?.skuDetails?.MRP_price),
              parseInt(product?.skuDetails?.NDP_Price),
              parseInt(product?.skuDetails?.GST)
            )) * product?.buy_quantity;
    });
  return parseInt(totalAmount);
};

// Function to calculate the shipping cost for the selected shipping mode
export const calculateShippingCost = (cart) => {
  let totalAmount = 0;
  cart &&
    cart?.forEach((product) => {
      totalAmount +=
        !product?.skuDetails?.SKU_ID?.startsWith("D") &&
        (product.skuDetails?.Offer_price
          ? calculateOfferPrice(
              parseInt(product.skuDetails?.MRP_price),
              parseInt(product.skuDetails?.Offer_price),
              product?.skuDetails?.GST
            )
          : calculateDealerPrice(
              parseInt(product.skuDetails?.MRP_price),
              parseInt(product.skuDetails?.NDP_Price),
              product?.skuDetails?.GST
            )) * product?.buy_quantity;
    });

  if (totalAmount >= 3000) {
    return 0;
  } else {
    return 250;
  }
};

export const fetchUserShipping = async (UserID) => {
  try {
    const response = await fetchUserShippingById(UserID);
    if (response && response.data) {
      return response?.data?.shipping;
    }
    throw new Error("UserType not found in response");
  } catch (error) {
    console.error("Error fetching user role:", error);
    return null;
  }
};

export const formatDateAndTime = (date) => {
  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Get hours, minutes, and AM/PM format
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

  // Format time as "HH:MM AM/PM"
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  // Combine the formatted date and time
  return `${day}.${month}.${year} - ${formattedTime}`;
};

export const formatAadharNumber = (aadharNumber) => {
  // Check if aadharNumber is provided and has 12 characters
  if (aadharNumber && aadharNumber.length === 12) {
    // Replace first 8 characters with 'X' and split the Aadhar number into groups of 4 characters
    const formattedAadharNumber = "XXXX-XXXX-" + aadharNumber.slice(-4);
    return formattedAadharNumber;
  } else {
    return aadharNumber; // Return original number if not valid
  }
};

export const calculateGST = (price, gstPercentage) => {
  // Ensure both price and gstPercentage are valid numbers
  if (
    typeof price !== "number" ||
    isNaN(price) ||
    typeof gstPercentage !== "number" ||
    isNaN(gstPercentage)
  ) {
    throw new Error("Invalid input. Price and GST percentage must be numbers.");
  }

  // Calculate GST amount
  const gstAmount = (price * gstPercentage) / 100;
  return gstAmount;
};

export const handleCurrentPrice = (data) => {
  const originalPrice = data?.skuDetails?.Offer_price
    ? calculateOfferPrice(
        parseInt(data?.skuDetails?.MRP_price),
        parseInt(data?.skuDetails?.Offer_price),
        data?.skuDetails?.GST
      )
    : calculateDealerPrice(
        parseInt(data?.skuDetails?.MRP_price),
        parseInt(data?.skuDetails?.NDP_Price),
        data?.skuDetails?.GST
      );
  const discountedPrice = originalPrice;
  const finalPrice = discountedPrice * data?.buy_quantity;
  return finalPrice;
};

export const calculateAmountToPay = (
  cart,
  couponDiscount,
  useWalletAmount,
  useCreditAmount,
  setUseWalletAmount,
  setUseCreditAmount
) => {
  let AmountAfterWallet = 0;
  let totalAmount = calculateTotalAmount(cart);
  let shippingCost = calculateShippingCost(cart);

  // Apply coupon discount first
  let discountedTotal = totalAmount + shippingCost - couponDiscount;

  // Ensure discountedTotal is not negative after applying coupon
  discountedTotal = Math.max(discountedTotal, 0);

  // Use wallet amount (but not more than the discountedTotal)
  if (useWalletAmount > discountedTotal) {
    setUseWalletAmount(parseInt(discountedTotal)); // Reduce wallet amount to what's required
  }
  AmountAfterWallet = discountedTotal - useWalletAmount;

  // Use credit amount (but not more than the remaining AmountAfterWallet)
  if (useCreditAmount > AmountAfterWallet) {
    setUseCreditAmount(parseInt(AmountAfterWallet)); // Reduce credit amount to what's required
  }
  AmountAfterWallet -= useCreditAmount;

  // Ensure final amount to pay is not negative
  const amountToPay = Math.max(AmountAfterWallet, 0);

  // Return final amount and the used amounts from wallet and credit
  return amountToPay;
};

export const steps = [
  { label: "Cart", icon: <ShoppingCartIcon style={{ fontSize: "18px" }} /> },
  { label: "Address", icon: <LocationOnIcon style={{ fontSize: "18px" }} /> },
  {
    label: "Payment",
    icon: <MonetizationOnIcon style={{ fontSize: "18px" }} />,
  },
];

export const trackSteps = [
  {
    label: "Order Confirmed",
    icon: <CheckSharpIcon style={{ fontSize: "18px" }} />,
  },
  {
    label: "Quality Check",
    icon: <CheckSharpIcon style={{ fontSize: "18px" }} />,
  },
  {
    label: "Dispatched Item",
    icon: <CheckSharpIcon style={{ fontSize: "18px" }} />,
  },
  {
    label: "Product Delivered",
    icon: <CheckSharpIcon style={{ fontSize: "18px" }} />,
  },
];

export const formatShortDate = (date) => {
  const updatedDate = new Date(date);
  
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = updatedDate.getDate();
  const month = months[updatedDate.getMonth()];

  // Function to get the day suffix
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${month} ${day}${getDaySuffix(day)}`;
};


export const calculateDueDate = (orderDate) => {
  const currentDate = new Date(); // Get today's date
  const orderDateObj = new Date(orderDate); // Parse the order date
  const dueDate = new Date(orderDateObj); // Create a new date object for due date
  dueDate.setDate(orderDateObj.getDate() + 45); // Add 45 days to the order date
  
  // Calculate the difference in time between the due date and current date
  const timeDiff = dueDate.getTime() - currentDate.getTime();
  
  // Calculate the number of days left by dividing the time difference by milliseconds in a day
  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Rounding up to the nearest whole day
  
  return daysLeft;
};

export const getFormattedDueDate = (orderDate) => {
  const dueDate = new Date(orderDate); // Parse the order date
  dueDate.setDate(dueDate.getDate() + 45); // Add 45 days to the order date

  // Get the day, month, and year for formatting
  const day = String(dueDate.getDate()).padStart(2, '0');
  const year = dueDate.getFullYear();

  // Array of month names for formatting
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const month = monthNames[dueDate.getMonth()]; // Get the month name

  // Return the formatted due date string
  return `${day} ${month}, ${year}`;
};

