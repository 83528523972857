import React from "react";
import wishlistMoreLogo from "../../img/wishlist-more.png";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import { useNavigate } from "react-router";
import {
  fetchAllCartsAsync,
  removeAllFromCartsAsync,
} from "../../features/cartPage/cartPageSlice";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import CartProductCard from "../../mComponent/CartProductCard";
import CartNoProduct from "../../mComponent/CartNoProduct";

function MobileCartsAllProduct({ cart, userDetail, handleOpen, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddItemFromWishlist = () => {
    navigate("/wishlist");
  };
  const handleContinueShopping = () => {
    navigate("/");
  };

  const handleRemoveAllFromCarts = () => {
    handleOpen();
    dispatch(removeAllFromCartsAsync(userDetail.UserID)).then(() => {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      handleClose();
    });
  };

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center justify-between pb-2 px-3">
          <Button
            variant="outlined"
            sx={{
              height: "30px",
              border: "1.5px solid rgba(0, 0, 0, 0.8)",
              outline: "none",
              borderRadius: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 8px",
              backgroundColor: "transparent",
              textTransform: "none",
              color: "rgba(0, 0, 0, 0.8)",
              fontWeight: 600,
              fontSize: "15px",
              "&:hover":{
                border: "1.5px solid rgba(0, 0, 0, 0.8)",
              },
              "& svg": {
                fontSize: "18px",
                color: "rgba(0, 0, 0, 0.7)",
                marginLeft: "-5px",
              },
            }}
            onClick={handleContinueShopping}
          >
            <ChevronLeftSharpIcon /> Continue to shopping
          </Button>
          {cart && cart?.length > 0 && (
            <Button
              variant="Outlined"
              sx={{
                height: "30px",
                border: "1.5px solid #ba3737",
                outline: "none",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "none",
                padding: "0 8px",
                backgroundColor: "transparent",
                color: "#ba3737",
                fontWeight: 600,
                fontSize: "15px",
              }}
              onClick={handleRemoveAllFromCarts}
            >
              Remove all
            </Button>
          )}
        </div>
        {cart && cart.length > 0 && (
          <div className="w-full min-h-[60vh] flex flex-col">
            {cart?.map((data) => (
              <CartProductCard
                data={data}
                index={data?.skuDetails?.SKU_ID}
                key={data?.skuDetails?.SKU_ID}
                userDetail={userDetail}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
            ))}
            <div className="w-full p-3">
              <Button
                variant="contained"
                onClick={handleAddItemFromWishlist}
                sx={{
                  width: "100%",
                  height: "40px",
                  background: "#283248",
                  outline: "none",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "15px",
                  "&:hover":{
                    background: "#283248",
                  },
                  "& img": {
                    width: "auto",
                    height: "20px",
                    marginRight: "8px",
                  },
                }}
              >
                <img loading="lazy" src={wishlistMoreLogo} alt="wishlist-logo" />
                Add more items from wishlist
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MobileCartsAllProduct;
