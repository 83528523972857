import { API_BASE_URL } from "../../config";

// Fetch Credit Add
export function fetchCreditAdd(UserID, creditLimitID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}credit/add/${UserID}/${creditLimitID}`);
    const data = await response.json();
    resolve({ data });
  });
}

// Fetch all User with credit
export function fetchCreditUser() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}/list/credit_users`);
    const data = await response.json();
    resolve({ data });
  });
}

// Fetch Available Credit
export function fetchAvailableCredit(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}credit/available_credit/${UserID}`);
    const data = await response.json();
    resolve({ data });
  });
}

// Fetch Credit Page Details
export function fetchCreditPageDetails(UserID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}credit/page_details/${UserID}`);
    const data = await response.json();
    resolve({ data });
  });
}

// Pay Order Using Credit
export function payOrderUsingCredit(creditData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}credit/pay_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(creditData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// Fetch Credit Payment
export function fetchCreditPayment(userID, orderNumber) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}credit/payment/${userID}/${orderNumber}`);
    const data = await response.json();
    resolve({ data });
  });
}

// Fetch Credit Payment Status
export function fetchCreditPaymentStatus(link_ID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}credit/payment_status/${link_ID}`);
    const data = await response.json();
    resolve({ data });
  });
}

// Fetch Spend Amount Payment
export function fetchSpendAmountPay(userID, orderNo) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}credit/spend_amount_pay/${userID}/${orderNo}`);
    const data = await response.json();
    resolve({ data });
  });
}
