import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllPreOrderUserDetailsAsync,
  removeAllFromPreorderCartsAsync,
  selectAllPreordersUser,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import PreorderCard from "../../dComponent/Preorder/PreorderCard";
import Fulfilled from "../../dComponent/Fullfilled";

function AdminPreOrder() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const userDetail = useSelector(selectUser);
  const userPreBook = useSelector(selectAllPreordersUser);
  // console.log(userPreBook);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(userPreBook);

  const [openOrder, setOpenOrder] = useState(null);
  const [fFilled, setFFilled] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID)).then(
        () => {
          handleClose();
        }
      );
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    if (userPreBook) {
      setFilteredUsers(
        userPreBook &&
          userPreBook?.filter(
            (user) =>
              user?.FirstName.toLowerCase()?.includes(
                searchQuery?.toLowerCase()
              ) ||
              user?.LastName.toLowerCase()?.includes(
                searchQuery?.toLowerCase()
              ) ||
              user?.PhoneNumber?.includes(searchQuery)
          )
      );
    }
  }, [searchQuery, userPreBook]);

  const wantToFulfilled = (id) => {
    // console.log(fFilled);
    handleOpen();
    dispatch(removeAllFromPreorderCartsAsync(fFilled))?.then(() => {
      dispatch(fetchAllPreOrderUserDetailsAsync(userDetail?.UserID));
      setFFilled(null);
      handleClose();
    });
  };
  const handleFulfilled = (id) => {
    setFFilled(id);
  };

  const calculateTotalAmount = (user) => {
    let totalAmount = 0;

    if (user && user?.Preorders) {
      for (const preorder of user?.Preorders) {
        // Choose the price: offer price if available, otherwise NDP price
        const price = preorder?.Offer_price
          ? calculateOfferPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.Offer_price),
              preorder?.GST
            )
          : calculateDealerPrice(
              parseInt(preorder?.MRP_price),
              parseInt(preorder?.NDP_price),
              preorder?.GST
            );

        // Calculate the amount for this preorder item
        const amount = price * preorder?.quantity;

        // Add to the total amount
        totalAmount += amount;
      }
    }

    return totalAmount;
  };

  const calculateOverallAmount = () => {
    let overallAmount = 0;

    if (userPreBook && userPreBook?.length > 0) {
      for (const user of userPreBook) {
        if (user && user?.Preorders) {
          for (const preorder of user?.Preorders) {
            // Choose the price: offer price if available, otherwise NDP price
            const price = preorder?.Offer_price
              ? calculateOfferPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.Offer_price),
                  preorder?.GST
                )
              : calculateDealerPrice(
                  parseInt(preorder?.MRP_price),
                  parseInt(preorder?.NDP_price),
                  preorder?.GST
                );

            // Calculate the amount for this preorder item
            const amount = price * preorder?.quantity;

            // Add to the total amount
            overallAmount += amount;
          }
        }
      }
    }
    return overallAmount;
  };

  return (
    <div className="admin-dashboard-right-pre-order-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="admin-dashboard-right-top-heading">
        <span className="admin-dashboard-heading-name">Pre-Orders</span>
        <img loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="admin-logo"
        />
      </div>
      <div className="admin-dashboard-main-container">
        <div className="admin-dashboard-middle-container">
          <div className="admin-dashboard-total-preorder-container">
            <span className="admin-dashboard-heading-total">
              Total Pre-Orders
            </span>
            <span className="admin-dashboard-total-count">
              {userPreBook && Array.isArray(userPreBook)
                ? userPreBook?.length
                : 0}
            </span>
            <WorkHistoryOutlinedIcon />
          </div>
          <div
            className="admin-dashboard-middle-search-container"
            style={{ position: "relative", top: "20px", right: "75px" }}
          >
            <SearchIcon />
            <input
              type="text"
              placeholder="Search by name or phone"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="admin-dashboard-search-input"
            />
          </div>
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="admin-dashboard-main-user-preorder-container">
            <div className="admin-dashboard-main-user-preorder-header">
              <span className="admin-dashboard-main-user-preorder-heading">
                Recent Pre-Orders
              </span>
              <span className="admin-dashboard-main-user-preorder-overall">
                Overall Amount:
                <b style={{ fontSize: "16px" }}>
                  {" "}
                  ₹{formatWithCommasWithDecimal(calculateOverallAmount())}
                </b>
              </span>
            </div>
            <div className="admin-dashboard-main-user-item-head">
              <span className="admin-dashboard-serial-no">S.No</span>
              <span className="admin-dashboard-item-each-head">User Name</span>
              <span className="admin-dashboard-item-each-head">
                Phone Number
              </span>
              <span className="admin-dashboard-item-each-head">Email</span>
              <span className="admin-dashboard-item-each-head">Order</span>
              <span className="admin-dashboard-item-dot">-</span>
            </div>
            <div className="admin-dashboard-main-user-prebook-container">
              {filteredUsers &&
                Array.isArray(filteredUsers) &&
                filteredUsers?.map((user, index) => (
                  <div className="admin-dashboard-main-each-user" key={index}>
                    <div
                      className="admin-dashboard-main-each-user-top"
                      style={{
                        boxShadow:
                          user?.UserID === openOrder &&
                          "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                      }}
                      key={index}
                    >
                      <span className="admin-dashboard-user-sno">
                        {index + 1}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.FirstName}&nbsp;{user?.LastName}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.PhoneNumber}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.Email}
                      </span>
                      <span className="admin-user-each-attr">
                        Total Amount: ₹
                        {formatWithCommasWithDecimal(
                          calculateTotalAmount(user)
                        )}
                        {openOrder === user?.UserID ? (
                          <KeyboardArrowDownOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpenOrder(null)}
                          />
                        ) : (
                          <ChevronRightOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpenOrder(user?.UserID)}
                          />
                        )}
                      </span>
                      <span
                        className="admin-user-req-fulfilled"
                        onClick={() => handleFulfilled(user?.UserID)}
                      >
                        Fulfilled
                      </span>
                      {fFilled !== null && (
                        <Fulfilled
                          fFilled={fFilled}
                          handleFulfilled={handleFulfilled}
                          wantToFulfilled={wantToFulfilled}
                          user={user}
                        />
                      )}
                    </div>
                    {openOrder === user?.UserID && (
                      <div className="admin-user-bottom-products">
                        <div className="main-carts-products-div-container">
                          {user?.Preorders && user?.Preorders.length > 0 && (
                            <div className="w-full flex flex-col bg-white shadow-md rounded-md p-6 bg-white-1">
                              <div className="w-full flex justify-between text-gray-700 font-semibold mb-4">
                                <span className="w-1/4">Product</span>
                                <span className="w-1/4 text-center">Qty</span>
                                <span className="w-1/4 text-center">
                                  Price per unit
                                </span>
                                <span className="w-1/4 text-right">
                                  Total Price <b>(Incl. GST)</b>
                                </span>
                              </div>
                              <div className="space-y-4">
                                {user?.Preorders?.map((data, index) => (
                                  <div
                                    className="w-full h-[150px] flex items-center justify-between bg-gray-100 p-4"
                                    key={index}
                                  >
                                    <div className="h-full flex items-center space-x-4 w-1/4">
                                      <div className="w-16 h-20 rounded-md bg-white-1 overflow-hidden">
                                        <LazyLoadImage
                                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                                            data?.image && data.image
                                          }`}
                                          alt="product-img"
                                          effect="blur"
                                          className="w-16 h-20 object-contain p-2"
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        {data?.manufacture_images ? (
                                          <LazyLoadImage
                                            effect="blur"
                                            className="w-auto h-5 mb-1"
                                            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${data?.manufacture_images}`}
                                            alt="brand-img"
                                          />
                                        ) : (
                                          <h3 className="text-sm text-peach italic">
                                            {data?.manufacture_name}
                                          </h3>
                                        )}
                                        <span className="text-sm text-black-1/80 ">
                                          {data?.prod_name?.slice(0, 40)}
                                        </span>
                                        <div className="flex space-x-2 mt-2">
                                          {data?.Size_Name && (
                                            <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                                              {data?.Size_Name}
                                            </span>
                                          )}
                                          {data?.Colour_Name && (
                                            <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                                              {data?.Colour_Name}
                                            </span>
                                          )}
                                          {data?.Varient_Name && (
                                            <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                                              {data?.Varient_Name}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {/* Quantity */}
                                    <div className="text-center w-1/4">
                                      <span className="text-lg text-gray-700 text-center">
                                        {data?.quantity}
                                      </span>
                                    </div>

                                    {/* Pricing */}
                                    <div className="w-1/4 text-center">
                                      <div className="text-[14px] text-black-1/80">
                                        Dealer Price-&nbsp;
                                        {data?.NDP_price && (
                                          <span className="text-xs text-black-1/90">
                                            ₹
                                            {formatWithCommasWithDecimal(
                                              calculateDealerPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.NDP_price),
                                                data?.GST
                                              )
                                            )}
                                          </span>
                                        )}
                                        {data?.Offer_price && (
                                          <span className="text-xs text-green-500 font-semibold">
                                            ₹
                                            {formatWithCommasWithDecimal(
                                              calculateOfferPrice(
                                                parseInt(data?.MRP_price),
                                                parseInt(data?.Offer_price),
                                                data?.GST
                                              )
                                            )}
                                          </span>
                                        )}
                                      </div>
                                      {data?.MRP_price && (
                                        <span className="block text-xs text-black-1/60">
                                          MRP: ₹
                                          {formatWithCommasWithDecimal(
                                            parseInt(data?.MRP_price)
                                          )}
                                        </span>
                                      )}
                                    </div>

                                    {/* Total Price */}
                                    <div className="w-1/4 flex items-center text-right">
                                      <div className="w-full flex flex-col items-end">
                                        <div className="text-lg font-semibold text-gray-800">
                                          ₹
                                          {formatWithCommasWithDecimal(
                                            (data?.Offer_price
                                              ? calculateOfferPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.Offer_price),
                                                  data?.GST
                                                )
                                              : calculateDealerPrice(
                                                  parseInt(data?.MRP_price),
                                                  parseInt(data?.NDP_price),
                                                  data?.GST
                                                )) * data?.quantity
                                          )}
                                        </div>
                                        {calculateDiscountPercentage(
                                          data?.MRP_price,
                                          data?.NDP_price
                                        ) > 0 && (
                                          <div className="flex items-center text-xs text-green font-sans">
                                            <LocalOfferOutlinedIcon
                                              className="mr-1 text-green"
                                              style={{ fontSize: "14px" }}
                                            />
                                            <span>
                                              {calculateDiscountPercentage(
                                                data?.MRP_price,
                                                data?.NDP_price
                                              )}
                                              % Dealer Margin
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminPreOrder;
