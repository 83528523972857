import React, { useEffect, useState } from "react";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  fetchAllUsersShippingAsync,
  selectAllUsersShipping,
  updateUserShippingAsync,
  fetchUserShippingByIdAsync,
} from "../../features/cartPage/cartPageSlice";
import SearchIcon from "@mui/icons-material/Search";
import NoCartItem from "../Carts/NoCartItem";
import { Switch } from "@mui/material";

function AdminShipping() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const userShipping = useSelector(selectAllUsersShipping);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUserShipping, setFilteredUserShipping] = useState(userShipping);
  const [countArray, setCountArray] = useState({
    count: 0,
    shipping: 0,
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    handleOpen();
    if (userDetail?.UserID)
      dispatch(fetchAllUsersShippingAsync()).then(() => {
        handleClose();
      });
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    // Filter users whenever the search query changes
    // console.log(searchQuery);
    if (userShipping) {
      setFilteredUserShipping(
        userShipping?.filter(
          (user) =>
            user?.FirstName.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery)
        )
      );
    }
  }, [searchQuery, userShipping]);

  useEffect(() => {
    let cnt = 0;
    let shp = 0;
    for (const user of userShipping) {
      cnt++;
      if (user?.shipping === "true") shp++;
    }
    setCountArray({
      count: cnt,
      shipping: shp,
    });
  }, [userShipping]);

  const handleToggleRole = (UserID) => {
    handleOpen();
    dispatch(updateUserShippingAsync(UserID))
      .then(() => {
        dispatch(fetchAllUsersShippingAsync());
      })
      .then(() => {
        handleClose();
      });
  };

  return (
    <div className="admin-dashboard-right-pre-order-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="admin-dashboard-right-top-heading">
        <span className="admin-dashboard-heading-name">Free Shipping</span>
        <img loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="admin-logo"
        />
      </div>
      <div className="admin-dashboard-main-container">
        <div className="admin-dashboard-middle-container">
          <div className="admin-dashboard-total-preorder-container">
            <span className="admin-dashboard-heading-total">Total Users</span>
            <span className="admin-dashboard-total-count">
              {filteredUserShipping && Array.isArray(filteredUserShipping) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      color: "rgba(0,0,0,0.6",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.5)",
                      }}
                    >
                      User:
                    </b>{" "}
                    {countArray?.count}
                  </span>
                  <b style={{ margin: "0 5px", color: "rgba(0,0,0,0.3)" }}>|</b>
                  <span
                    style={{
                      color: "rgba(0,0,0,0.6",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.5)",
                      }}
                    >
                      Free Shipping:
                    </b>{" "}
                    {countArray?.shipping}
                  </span>
                </div>
              )}
            </span>
          </div>
          <div
            className="admin-dashboard-middle-search-container"
            style={{ position: "relative", top: "20px", right: "75px" }}
          >
            <SearchIcon />
            <input
              type="text"
              placeholder="Search by name or phone"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="admin-dashboard-search-input"
            />
          </div>
        </div>
        {filteredUserShipping && Array.isArray(filteredUserShipping) ? (
          <div className="admin-dashboard-main-user-preorder-container">
            <span className="admin-dashboard-main-user-preorder-heading">
              All Users
            </span>
            <div className="admin-dashboard-main-user-item-head">
              <span className="admin-dashboard-serial-no ">S.No</span>
              <span className="admin-dashboard-item-each-head ">User Name</span>
              <span className="admin-dashboard-item-each-head ">
                Phone Number
              </span>
              <span className="admin-dashboard-item-each-head ">
                Business Name
              </span>
              <span className="admin-dashboard-item-each-head ">
                Dealer Type
              </span>
            </div>
            <div className="admin-dashboard-main-user-prebook-container">
              {filteredUserShipping &&
                Array.isArray(filteredUserShipping) &&
                filteredUserShipping?.map((user, index) => (
                  <div className="admin-dashboard-main-each-user" key={index}>
                    <div className="admin-dashboard-main-each-user-top">
                      <span className="admin-dashboard-user-sno ">
                        {index + 1}
                      </span>
                      <span className="admin-user-each-attr ">
                        {user?.FirstName}&nbsp;{user?.LastName}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.PhoneNumber}
                      </span>
                      <span className="admin-user-each-attr">
                        {user?.business_name}
                      </span>
                      <span className="admin-user-each-attr">
                        <span>
                          <b
                            className={`role-label ${
                              user?.shipping === "true" ? "active" : ""
                            }`}
                          >
                            cost ₹250
                            
                          </b>
                          <Switch
                            checked={user?.shipping === "false"}
                            onChange={() => handleToggleRole(user?.UserID)}
                          />
                          <b
                            className={`role-label ${
                              user?.shipping === "false" ? "active" : ""
                            }`}
                          >
                            Free
                          </b>
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <NoCartItem text={"User"} />
        )}
      </div>
    </div>
  );
}

export default AdminShipping;
