import React, { useCallback, useEffect, useState } from "react";
import MobileCartsAllProduct from "./MobileCartsAllProduct";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { selectUser } from "../../features/auth/authSlice";
import { Box } from "@mui/material";
import CartBreadcrum from "../../mComponent/CartBreadcrum";
import CartNoProduct from "../../mComponent/CartNoProduct";
import {
  handleChangeQtyOfProductCarts,
  handleRemoveFromCarts,
} from "../../utils/dispatch";
import {
  fetchAllAddressAsync,
  selectAllAddress,
  selectSelectedAddress,
} from "../../features/cartPage/addressSlice";
import {
  fetchCouponAsync,
  getWalletAsync,
  selectAllCoupon,
  selectWallet,
} from "../../features/cartPage/walletSlice";
import {
  fetchCreditPageDetailsAsync,
  selectAllCreditData,
} from "../../features/credit/creditSlice";
import { handleGeneratePaymentURL } from "../../utils/payment";
import { calculateAmountToPay, fetchUserShipping } from "../../utils/utils";
import { premiumUser } from "../../features/cartPage/walletAPI";
import DefaultContact from "../../components/Carts/DefaultContact";
import PaymentAccordian from "../../components/Carts/PaymentAccordian";
import PriceDetail from "../../components/Carts/PriceDetail";
import OrderConfirmed from "../../components/Carts/OrderConfirmed";

const MobileCarts = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [generatePaymentUrl, setGeneratePaymentUrl] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [openPaymentModel, setOpenPaymentModel] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [freeShipping, setFreeShipping] = useState(false);
  const [premUser, setPremUser] = useState(false);

  const userDetail = useSelector(selectUser);
  const cart = useSelector(selectAllCarts);
  const selectedAddress = useSelector(selectSelectedAddress);
  const addresses = useSelector(selectAllAddress);

  // Fetch Carts,Wallet,Coupon
  useEffect(() => {
    if (userDetail && userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
      dispatch(fetchAllAddressAsync(userDetail?.UserID));
      dispatch(fetchCouponAsync());
      dispatch(getWalletAsync(userDetail?.UserID));
      dispatch(fetchCreditPageDetailsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail]);

  // Managing Wallet
  const [useWalletAmount, setUseWalletAmount] = useState(0);
  const walletAmount = useSelector(selectWallet)?.balance;

  // Managing Coupon
  const [validCoupon, setValidCoupon] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState({
    couponCode: "",
    couponDiscountPercentage: 0,
    couponDiscountTaken: 0,
  });
  const couponList = useSelector(selectAllCoupon);

  // Managing Credit of line
  const creditData = useSelector(selectAllCreditData);
  const [useCreditAmount, setUseCreditAmount] = useState(0);

  // For deleting product automatically
  useEffect(() => {
    const fetchData = async () => {
      handleOpen();

      // Function to adjust quantities
      const adjustQuantityToAvailable = async () => {
        let quantityAdjustedFlag = false; // Local flag for adjustment

        for (const item of cart) {
          const availableQuantity = item?.skuDetails?.qty || 0;

          // Remove product if available quantity is 0
          if (availableQuantity < parseInt(item?.skuDetails?.Bundle)) {
            await handleRemoveFromCarts(
              item,
              userDetail,
              dispatch,
              handleOpen,
              handleClose
            );
            quantityAdjustedFlag = true; // Set flag to true
            continue; // Skip processing further for this item
          }

          const updatedQty = Math.min(item?.buy_quantity, availableQuantity);
          const newQty =
            (parseInt(availableQuantity) / parseInt(item?.skuDetails?.Bundle)) *
            parseInt(item?.skuDetails?.Bundle);

          // Update quantity only if buy_quantity exceeds available quantity
          if (item.buy_quantity !== updatedQty) {
            await handleChangeQtyOfProductCarts(
              item,
              userDetail,
              newQty,
              dispatch,
              handleOpen,
              handleClose
            );
            quantityAdjustedFlag = true; // Set flag to true
          }
        }

        if (quantityAdjustedFlag) {
          await dispatch(fetchAllCartsAsync(userDetail?.UserID));
        }

        handleClose(); // Close the loader here
      };

      // Call the function to adjust quantities when component mounts
      await adjustQuantityToAvailable();
    };

    fetchData();

    return () => clearTimeout(2000);
  }, [dispatch, cart, userDetail]);

  const checkPremiumUser = useCallback(async () => {
    try {
      const response = await premiumUser(userDetail?.UserID);
      if (response && response.data && response.data.message) {
        setPremUser(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [userDetail?.UserID]);

  // Use useEffect to call the function
  useEffect(() => {
    if (userDetail?.UserID) {
      checkPremiumUser();
    }
  }, [checkPremiumUser, userDetail]);

  const fetchAndSetShipping = useCallback(async () => {
    if (userDetail?.UserID) {
      try {
        const userShipping = await fetchUserShipping(userDetail?.UserID);

        if (userShipping === "false") {
          setFreeShipping(true);
        } else {
          setFreeShipping(false);
        }
      } catch (error) {
        console.error("Error fetching user shipping info:", error);
      }
    }
  }, [userDetail?.UserID]);

  useEffect(() => {
    if (userDetail?.UserID) {
      fetchAndSetShipping();
    }
  }, [fetchAndSetShipping, userDetail]);

  // Generate Payment URL Call
  useEffect(() => {
    const generateUrlAsync = async () => {
      if (generatePaymentUrl) {
        const amountToPay = parseInt(
          await calculateAmountToPay(
            cart,
            selectedCoupon?.couponDiscountTaken,
            useWalletAmount,
            useCreditAmount,
            setUseWalletAmount,
            setUseCreditAmount
          )
        );
        await handleGeneratePaymentURL(
          userDetail,
          setPaymentSessionId,
          setTransactionId,
          amountToPay
        );
      }
    };

    generateUrlAsync();
  }, [generatePaymentUrl]);

  return (
    <div className="w-screen h-screen">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          width: "100vw",
          zIndex: 1000,
          backgroundColor: "white",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CartBreadcrum
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </Box>
      {cart && cart?.length > 0 ? (
        <div className="w-full h-full flex flex-col justify-between mt-[65px] pt-3">
          <div className="w-full">
            {currentStep === 1 && (
              <MobileCartsAllProduct
                cart={cart}
                userDetail={userDetail}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
            )}
            {currentStep === 2 && (
              <DefaultContact
                addresses={addresses}
                userDetail={userDetail}
                selectedAddress={selectedAddress}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
            )}
            {currentStep === 3 && (
              <PaymentAccordian
                userDetail={userDetail}
                walletAmount={walletAmount}
                useWalletAmount={useWalletAmount}
                setUseWalletAmount={setUseWalletAmount}
                errors={errors}
                setErrors={setErrors}
                couponList={couponList}
                validCoupon={validCoupon}
                setValidCoupon={setValidCoupon}
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
                cart={cart}
                creditData={creditData}
                useCreditAmount={useCreditAmount}
                setUseCreditAmount={setUseCreditAmount}
                setGeneratePaymentUrl={setGeneratePaymentUrl}
                setOpenPaymentModel={setOpenPaymentModel}
              />
            )}
          </div>
          <div className="w-full">
            <PriceDetail
              cart={cart}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              useWalletAmount={useWalletAmount}
              selectedCoupon={selectedCoupon}
              useCreditAmount={useCreditAmount}
              setUseWalletAmount={setUseWalletAmount}
              setUseCreditAmount={setUseCreditAmount}
              selectedAddress={selectedAddress}
              setGeneratePaymentUrl={setGeneratePaymentUrl}
              setOpenPaymentModel={setOpenPaymentModel}
            />
          </div>
        </div>
      ) : (
        <CartNoProduct text={"Carts"} />
      )}

      {generatePaymentUrl && openPaymentModel && (
        <OrderConfirmed
          cart={cart}
          userDetail={userDetail}
          paymentSessionId={paymentSessionId}
          transactionId={transactionId}
          selectedAddress={selectedAddress}
          selectedCoupon={selectedCoupon}
          useWalletAmount={useWalletAmount}
          setUseWalletAmount={setUseWalletAmount}
          useCreditAmount={useCreditAmount}
          setUseCreditAmount={setUseCreditAmount}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          generatePaymentUrl={generatePaymentUrl}
          openPaymentModel={openPaymentModel}
          setOpenPaymentModel={setOpenPaymentModel}
        />
      )}
    </div>
  );
};

export default MobileCarts;
