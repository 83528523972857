import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IMAGE_BASE } from "../../config";
import {
  fetchCreditAddAsync,
  fetchCreditUserAsync,
  selectAllCreditUser,
} from "../../features/credit/creditSlice";
import silverIcon from "../../assets/Badge-Silver.png";
import goldIcon from "../../assets/Badge-Gold.png";
import DiamondIcon from "../../assets/Badge-Platinum.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import toast from "react-hot-toast";

const AdminLOC = () => {
  const dispatch = useDispatch();
  const creditUser = useSelector(selectAllCreditUser);
  const userDetail = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCreditType, setSelectedCreditType] = useState("");
  const creditTypes = [
    { id: 1, label: "Silver" },
    { id: 2, label: "Gold" },
    { id: 3, label: "Diamond" },
  ];

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    handleOpen();
    if (userDetail?.UserID) {
      dispatch(fetchCreditUserAsync()).then(() => handleClose());
    }
  }, [dispatch, userDetail?.UserID]);

  const handleCreditActivation = () => {
    if (selectedUser && selectedCreditType) {
      dispatch(
        fetchCreditAddAsync({
          UserID: selectedUser,
          creditLimitID: selectedCreditType,
        })
      ).then(()=>{
        dispatch(fetchCreditUserAsync())
        toast.success("Line of Credit Feature successfully activated")
      });
    } else {
      toast.error("Please select both a user and credit type");
    }
  };

  const getCreditIcon = (creditType) => {
    switch (creditType) {
      case "Silver":
        return silverIcon;
      case "Gold":
        return goldIcon;
      case "Diamond":
        return DiamondIcon;
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 bg-white opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Line of Credit
        </span>
        <img loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full px-5">
        <div className="bg-white p-6 mt-6 rounded-lg shadow-md bg-white-1">
          <h3 className="text-lg text-gray-800 font-semibold mb-4">
            Activate Credit for User
          </h3>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <div className="flex-1 max-sm:w-full">
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                displayEmpty
                fullWidth
                className="text-gray-700"
                sx={{
                  height: "40px",
                }}
              >
                <MenuItem value="">
                  <em>Select User</em>
                </MenuItem>
                {creditUser?.map((user) => (
                  <MenuItem key={user.UserID} value={user.UserID}>
                    {user.business_name || user.FirstName || "N/A"}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex-1 max-sm:w-full">
              <Select
                value={selectedCreditType}
                onChange={(e) => setSelectedCreditType(e.target.value)}
                displayEmpty
                fullWidth
                className="text-gray-700"
                sx={{
                  height: "40px",
                }}
              >
                <MenuItem value="">
                  <em>Select Credit Type</em>
                </MenuItem>
                {creditTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button
              variant="contained"
              sx={{
                background: "#ba3737",
                textTransform:"none",
                letterSpacing:"1px",
                fontSize:"16px",
                "&:hover": {
                  background: "#ba3737",
                },
              }}
              color="primary"
              onClick={handleCreditActivation}
              className="bg-[#ba3737] text-white max-sm:w-full"
            >
              Activate Credit
            </Button>
          </div>
        </div>
      </div>

      <div className="w-full h-auto bg-white mt-6 flex flex-col">
        {creditUser && Array.isArray(creditUser) && (
          <div className="w-full p-4">
            <h2 className="text-xl font-bold mb-4 text-black-1">All Users</h2>
            <TableContainer component={Paper} className="shadow-lg">
              <Table sx={{ minWidth: 650 }} aria-label="user credit table">
                <TableHead>
                  <TableRow className="bg-gray-100">
                    <TableCell sx={{textAlign:"center",fontWeight:"700",letterSpacing:"0.5px"}}>
                      S.No
                    </TableCell>
                    <TableCell sx={{textAlign:"center",fontWeight:"700",letterSpacing:"0.5px"}}>
                      Business Name
                    </TableCell>
                    <TableCell sx={{textAlign:"center",fontWeight:"700",letterSpacing:"0.5px"}}>
                      Phone Number
                    </TableCell>
                    <TableCell sx={{textAlign:"center",fontWeight:"700",letterSpacing:"0.5px"}}>
                      Credit Type
                    </TableCell>
                    <TableCell sx={{textAlign:"center",fontWeight:"700",letterSpacing:"0.5px"}}>
                      Available Credit
                    </TableCell>
                    <TableCell sx={{textAlign:"center",fontWeight:"700",letterSpacing:"0.5px"}}>
                      Spent Credit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditUser
                    ?.filter((user) => user.creditStatus === "Yes")
                    ?.map((user, index) => (
                      <TableRow key={index} className="hover:bg-gray-50">
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {user?.business_name?.slice(0, 45) || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {user?.PhoneNumber || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {getCreditIcon(user?.creditLimitCategory_Name) ? (
                            <div className="flex items-center justify-center">
                              <img loading="lazy"
                                src={getCreditIcon(
                                  user?.creditLimitCategory_Name
                                )}
                                alt={`${user?.creditLimitCategory_Name} icon`}
                                width={25}
                                height={25}
                              />
                              <span className="text-sm text-gray-700 font-medium ml-2">
                                {user?.creditLimitCategory_Name}
                              </span>
                            </div>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {user?.availableCredit || "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {user?.spentAmount || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLOC;
